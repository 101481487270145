.input_container {
  position: relative;
  display: flex;
  align-items: center;

  &.marginleft22 {
    margin-left: 22px
  }

  .label {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #484343;
    min-width: 150px;
    margin-right: 12px;
  }
  .required {
    margin: 0 2px;
    display: inline;
    font-size: 12px;
    line-height: 18px;
    color: #c21c44;
  }
  .input_border {
    width: 100%;
    min-width: 300px; 
    height: 48px;
    border-radius: 6px;
    border: 1px solid #ECE2E2;
    overflow: hidden;
    &:focus-within {
      border: 1px solid #FFC72C
    }
    &.phone {
      overflow: initial;
      border-radius: 4px;
    }
  }
  .textarea_border {
    width: 640px;
    height: 100px;
    border-radius: 6px;
    border: 1px solid #ECE2E2;
    background-color: #e8ebfa;
    overflow: hidden;
    margin-bottom: 26px;
    &:focus-within {
      border: 1px solid #FFC72C;
      
      .textarea_counter {
        visibility: visible;
      }
    }
  }

  .textarea_counter {
    position: absolute;
    right: 0;
    visibility: hidden;
  }

  .button_group {
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
  .avatar_container {
    position: relative;
    display: flex;
  }
  .input {
    padding-left: 16px;
    width: 101%;
    position: relative;
    border: none;
    height: 48px;
    left: -1px;
    top: -1px;
    font-size: 14px;

    &::placeholder {
      color: rgba(122, 105, 105, 0.50);
    }

    &:disabled {
      background: rgba(255, 255, 255, 0.4);
      color: rgba(72, 67, 67, 0.15);
      pointer-events: none;
      border: rgba(255, 255, 255, 0.4);
      cursor: not-allowed;
    }

    &.phone {
      left: initial;
      top: initial;
      width: 100%;
      height: 46px;
      border: none;
    }
  }
  .textarea {
    font-family: "RobotoFlex", sans-serif;
    padding-left: 5px;
    width: 100%;
    position: relative;
    border: none;
    height: 100%;
    resize: none;
    font-size: 14px;
    
    &::placeholder {
      color: rgba(122, 105, 105, 0.50);
    }
  }
}

.error_wrapper {
  position: absolute;
  top: 50px;
  left: 168px;
  display: flex;
  align-items: center;
  height: 0;
  transition: height ease-in-out 0.3s;
  overflow: hidden;

  &.second_on_line {
    left: 3px;
  }

  .error_icon {
    & svg {
      width: 12px;
      height: 12px;
    }
    margin-right: 4px;
  }
  .error_message {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #D32F2F;
  }
  &.show {
    height: 20px;
  }
}

.buttonClass.buttonClass.buttonClass {
  background-color: #e8ebfa;
  border: none;
  &:hover {
    background-color: #e8ebfa;
  }
}

.searchClass.searchClass {
  justify-content: flex-start;
}
.searchClass.searchClass:before {
}
.dropdownClass.dropdownClass.dropdownClass.dropdownClass {
  position: relative;
  width: 253px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  & li:hover {
    background: #e8ebfa;
  }
}

.crop :global(.ReactCrop__crop-selection),
.crop.crop :global(.ReactCrop__crop .ReactCrop__crop-selection) {
  border: none;
  box-shadow:
    0 0 0 0 #000,
    0 0 0 9999em #000000aa !important;
}

.crop.isPlaylist :global(.ReactCrop__crop-selection) {
  border-radius: 8px;
}

.cropWrap {
  display: flex;
  justify-content: center;
}
.crop.isPlaylist {
  max-width: 568px;
  max-height: 320px;
  border-radius: 8px;
}

.crop.crop {
  max-width: 568px;
  max-height: 320px;
  overflow: hidden;
}
.plusButton {
  height: 20px;
  margin-left: 14px;
  margin-right: 14px;
  cursor: pointer;
  &:hover{
    border-radius: var(--button-radius, 9999px);
    background: var(--bg-hover--channel, rgba(126, 122, 133, 0.04));
  }
}
.cancel_button {
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.crop.isPlaylist :global(.ReactCrop__child-wrapper > img) {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.slider_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
}
.slider {
  margin: 17px 0;
  max-width: 198px;
}

.separator {
  border-top: 1px solid #E0DFE2;
  margin-top: 5px;
  margin-bottom: 14px;
}

.button_group {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin: 0;

    &:first-child {
      margin-right: 18px;
      border: 0.6px solid #534b4f;
      &:hover {
        border: 0.6px solid #ffc72c;
      }
    }
  }
}

.avatar_image {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 1px solid #d7ddf7;
  & > div {
    transform: scale(4);
    top: 27px;
    left: 27px;
  }
}

.marginBottom48 {
  margin-bottom: 48px;
}