@import "../../mixins";

.wrapper {
  z-index: 101;
  position: relative;
  width: 100%;
  background: #f6f6f5;
  padding: 0 10px 20px 34px;

  @media (max-width: 1023.8px) {
    padding-left: 35px;
  }

  @media (min-width: 1023.9px)  {
    padding-left: 23px;
  }
  
  @media (min-width: 1324.9px) and (max-width:1545.8px) {
    padding-left: 17px;
  }

  @media (min-width: 1545.9px)  {
    padding-left: 13px;
  }
}
.minimize {
  width: calc(100% - 350px);
}
.hidden {
  position: absolute;
  display: none;
}
.playlists_wrapper {
  position: relative;
}

.loading.loading.loading {
  opacity: 0.7;
  background: rgba(128, 128, 128, 0.05);
}

.wrapper_drop_zone {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
  //-webkit-user-select: none;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  &.disable_scroll {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  &.many_playlists {
    height: calc(100vh - 64px);
  }
}
.scroll::-webkit-scrollbar {
  width: 8px;
  background-color: #fafaff;
}
.scroll::-webkit-scrollbar-thumb {
  display: block;
  border-radius: 8px;
  background-color: #e2e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.scroll::-webkit-scrollbar-track-piece {
  display: none;
  background-color: #fafaff;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.wrapper2 {
  min-height: calc(100vh - 126px);
  display: flex;
  height: calc(100vh - 126px);
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 28px;
  @include scroll_bar(8px);
}

.absolute_header_icon {
  position: absolute;
  right: 139px;
  top: 5px;
  z-index: 2;
}

.search {
  //margin-left: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}
.search_input {
  background-color: white;
  line-height: 21px;
  width: 190px;
  border: 0;
  font-size: 14px;
}

.search_input::placeholder {
  color: #a3aacc;
}

.search_input_wrapper {
  height: 40px;
  width: 260px;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;

  display: flex;
  align-items: center;
}
.clear_search {
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  margin-right: 7px;
}
.clear_search:hover {
  background: #e8ebfa;
}

.multiSelectPanelWrapper {
  height: 1px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 10px);
  position: fixed;
  z-index: 103;
}

.upload_file {
  position: relative;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-family: "RobotoFlex", sans-serif;
  border: 1px solid #0026e5;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: 32px;
  color: #0026e5;
  padding: 0 11px 0 5px;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }

  &.active {
    background: #e8ebfa;
  }
}
.upload_file svg path {
  stroke: #0026e5;
}

.orders {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 46px 20px 55px;
}
.cards_content {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  min-height: 100%;
  min-width: 610px;
}
.rows_content {
  position: relative;
  margin-left: 22px;
  margin-right: 19px;
  z-index: 102;
}
.rows_content.playlists {
  margin-left: 24px;
}

@media (max-width: 1300px) {
  //.cards_content {
  //    padding: 0 31px 20px 31px;
  //}
}
@media (max-width: 1730px) {
  .isSidebarOpened {
    .cards_content {
      padding: 0 31px 0 31px;
    }
    .rows_content {
      margin-left: 0;
      margin-right: 31px;
    }
  }
}

.title {
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 30px;
}
.fixed .title {
  font-size: 16px;
}
.title_or_search {
  display: flex;
  align-items: center;
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 24px;
}
.search_icon {
  margin-left: 22px;
  width: 20px;
  cursor: pointer;
}
.search_icon2 {
  cursor: pointer;

  padding: 8px 8px 8px 7px;
  width: 35px;
}
.folder_icon {
  margin-right: 8px;
  width: 20px;
}
.page {
  width: 171px;
  height: 280px;
  margin: 0 18px 20px 0;
}

.emptyFavorite {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: calc(100vh - 72px - 60px);
}
.emptyFavorite img {
  width: 48px;
  height: 58.67px;
}
.emptyRecent {
  position: relative;
  display: flex;
  flex-direction: column;
  /*margin: 35vh auto 0 auto;*/
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 72px - 60px);
}
.emptyFavorite_mainText {
  margin-top: 18px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #242633;
  text-align: center;
}

.emptyFavorite_infoText {
  margin-top: 8px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  text-align: center;
}

.popUp_wrapper {
}

.underlay {
  z-index: 100;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.add_file_popUp {
  z-index: 101;
  position: absolute;
  &.fixed {
    position: fixed;
  }
  width: fit-content;
  height: fit-content;
  right: 71px;
  top: 60px;
  background: #ffffff;
  box-shadow: 1px 1px 6px 0px #7a696940;
  border-radius: 12px;
  padding: 16px 16px;
  &.PlaylistBuilderAddFile {
    right: 40px;
    top: 0;
  }
  &.global_modal {
    right: 0;
    top: 0;
  }

  .uploading_bar {
    //padding-top: 10px;
    &_progress {
      display: flex;
      justify-content: space-between;
      .wrapper_close {
        cursor: pointer;
        margin-top: 24px;
        margin-right: 30px;
        border-radius: 4px;
        width: 32px;
        height: 32px;
        padding: 8px;
        svg {
        }
        &.active {
          background: #e8ebfa;
        }
      }
      .wrapper_close:hover {
        background: #e8ebfa;
      }
    }
    &_item {
      padding-top: 20px;
      display: flex;
      align-items: center;
      font-family: "RobotoFlex", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      svg {
        width: 30px;
        padding-right: 10px;
      }
      .progress {
        padding-left: 5px;
        color: #a3aacc;
      }
    }
  }

  .title {
    font-size: 12px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #d3c8c8;
  }

  .options_container {
    font-family: RobotoFlex;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #585858;
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    margin-top: 14px;
    .icon {
      padding-right: 5px;
    }
    .text_container {
      padding: 0 8px;
      border-radius: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 28px;
      margin-right: 11px;
      white-space: nowrap;

      &:hover {
        background: #ffeebf;
      }
      .text.active {
        color: #484343;
        background-color: #ffc72c;
      }
    }
    .text_container.active {
      color: #484343;
      background-color: #ffc72c;
    }
    .text_container.last {
      margin-right: 0;
    }
    .options {
      position: relative;
      display: flex;
      flex-direction: row;
      font-family: RobotoFlex;
      font-size: 13px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-align: left;
      color: #585858;
      text-transform: uppercase;
      //margin-bottom: 4px;
      cursor: pointer;
      .opt {
        //margin-right: 16px;
        & img {
          //margin-right: 5px;
        }
      }
    }
    .options_slider {
      .long_line {
        position: relative;
        width: 95%;
        height: 1px;
        bottom: -4px;
        background: #e4e7f7;
      }
      .under_computer {
        transition-property: width, left;
        position: relative;
        height: 2px;
        left: 0;
        width: 73px;
        bottom: -2px;
        background: #0026e5;
        transition-duration: 0.4s;
      }
      .under_embed {
        transition-property: width, left;
        position: relative;
        height: 2px;
        left: 88px;
        width: 79px;
        bottom: -2px;
        background: #0026e5;
        transition-duration: 0.4s;
      }

      .under_google {
        transition-property: width, left;
        position: relative;
        height: 2px;
        left: 183px;
        width: 118px;
        bottom: -2px;
        background: #0026e5;
        transition-duration: 0.4s;
      }

      .under_dropbox {
        transition-property: width, left;
        position: relative;
        height: 2px;
        left: 315px;
        width: 86px;
        bottom: -2px;
        background: #0026e5;
        transition-duration: 0.4s;
      }
    }
  }

  .drop_section {
    position: relative;
    width: 100%;
    height: 106px;
    background: #ffc72c26;
    border-radius: 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 45px;

    &.active {
      border: 1px solid blue;
    }

    .add_file_drop_zone {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
      top: 0;
      left: 0;
      z-index: 105;
    }

    .add_file_drop_zone.active {
      background: blue;
      height: 100%;
      width: 285px;
      opacity: 0.5;
    }

    .dnd_placeholder {
      position: relative;
      //width: 115px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: RobotoFlex;
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: #585858;
      img {
        width: 32px;
        height: 32px;
        margin-right: 13.82px;
      }

      .dnd_text {
        word-break: normal;
        max-width: 180px;
        font-family: "RobotoFlex", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        overflow-wrap: anywhere;
      
      }
      .or {
        white-space: nowrap;
      }
      .ms {
        margin-left: 22px;
      }
    }

    .file_picker {
      position: relative;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #484343;
      cursor: pointer;
      font-style: normal;
      text-transform: uppercase;
      height: 32px;
      border-radius: 6px;
      //padding: 12px 16px 12px 8px;
      background: #ffffff;
      border: 0.6px solid #ece2e2;
      font-family: RobotoFlex;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-align: left;
      padding: 0 5px;

      &:hover {
        background: #ffeebf;
        border: 0.6px solid #ffc72c;
      }
    }
  }

  .embed_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //width: 432px;
    padding-top: 7px;
    //padding-left:8px;

    .embed_input_wrapper {
      display: flex;
      align-items: center;
      //width: 364px;
      //height: 32px;

      box-sizing: border-box;

      input {
        padding-left: 8px;
        border: 1px solid #ece2e2;
        width: 420px;
        border-radius: 6px;
        font-family: RobotoFlex;
        font-size: 15px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #484343;
        &:focus {
          border: 1px solid #ffc72c;
        }
      }
      .embed_input {
        height: 32px;
        font-family: RobotoFlex;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        &::placeholder {
          color: #7a696980;
        }
      }
    }

    .embed_submit {
      position: relative;
      text-transform: uppercase;
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 32px;
      color: #ffffff;
      font-family: RobotoFlex;
      font-weight: 500;
      letter-spacing: 0.05em;
      text-align: left;
      font-size: 12px;
      line-height: 12px;
      cursor: pointer;
      padding: 0 7px;
      border: 0.6px solid #484343;
      box-shadow: 0px 1px 4px 0px #7a69691a;
      background-color: #534b4f;
    }
  }
}

.addFile_wrapper {
  position: relative;
}

.addFile_wrapper_emptyScreen {
  position: absolute;
  left: -180px;
  right: 0;
  top: 0;
}
.addFile_MainSlider_position {
  position: absolute;
  left: -230px;
  top: 43px;
}

.emptyNoResults {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 194px;
  min-height: calc(100vh - 72px - 60px);
  align-items: center;
  left: -20px;
  &.isSideBar {
    justify-content: flex-start;
    margin-top: 65px;
    left: 0;
  }
  &.collections {
    left: initial;
    margin-top: 240px;
  }
}
.emptyNoResults img {
  width: 64px;
  height: 64px;
}
.emptyNoResults .text_container img {
  width: 24px;
  height: 24px;
}

.emptyNoResults_icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  & .clocks {
    position: absolute;
    left: 22px;
    bottom: 11px;
  }
}

.emptyNoResults_mainText {
  margin-top: 23px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #242633;
  text-align: center;

  &.collections {
    margin-top: 7px;
  }
}

.emptyNoResults_infoText {
  margin-top: 8px;
  margin-bottom: 23px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  text-align: center;
}
.uploading_bar {
  position: relative;
}
.cancel_dialog {
  position: absolute;
  width: 300px;
  height: 150px;
  right: 27px;
  top: 58px;
  z-index: 212;

  /* White */
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  .text {
    color: #242633;
    padding-top: 24px;
    padding-left: 14px;
    padding-bottom: 20px;
    .title_dialog {
      font-weight: 500;
      font-size: 16.6px;
      line-height: 20px;
      margin-bottom: 8px;
    }
    .message {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .actions {
    padding: 4px 22px 0 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .accept {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      padding: 9px 0;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #c21c44;
      border: 1px solid #c21c44;
      width: 124px;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background: #e8ebfa;
      }
    }

    .decline {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      padding: 9px 4px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      width: 132px;
      cursor: pointer;
      border-radius: 4px;
      &:hover {
        background: #e8ebfa;
      }
    }
  }
}

.drop_zone {
  position: absolute;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  top: 0;
  left: 0;
  display: flex;
  visibility: hidden;
  z-index: 0;
  .dnd_hint {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 14px;
    width: 191px;
    height: 110px;
    bottom: 20px;
    background: #404d8c;
    box-shadow:
      0 1px 4px rgba(0, 0, 0, 0.25),
      0 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    color: white;
    .text {
      font-style: normal;
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }

    .icon {
      width: 48px;
      height: 48px;
      color: white;
      margin-bottom: 3px;
      svg {
        width: 100%;
        height: 100%;
        path {
          stroke: white;
        }
      }
    }
  }
}

.drop_zone.active {
  background: rgba(0, 38, 229, 0.12);
  visibility: visible;
  z-index: 105;
}

.selection_border {
  background: rgba(0, 162, 255, 0.4);
  position: fixed;
  z-index: 103;
}

.item {
  margin: 10px;
}

.loader_overlay {
  background-color: #00000088;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 105;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-wrapper-page {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 15;
  top: 0;
  left: 0;
  overflow: hidden;
}
.loader-wrapper-page.white {
  background-color: rgba(0, 0, 0, 0.02);
}

.admin_label {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 15px;
  background-color: crimson;
  color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid crimson;
  z-index: 999;
  white-space: nowrap;
  font-size: 14px;
  padding: 4px;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 20px rgba(0, 0, 0, 0.15);
  pointer-events: none;
}
