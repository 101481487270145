@import "./src/_mixins";

/*ForgotPassword.js*/
.wrapper {
  max-width: 700px;
  margin: 6px auto 140px;
}

.radio_selector {
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  margin-top: 9px;

  & .option {
    & .radioButton {
      margin-top: 5px;
    }

    display: flex;
    padding-bottom: 9px;

    & .time {
      //font-family: 'RobotoFlex', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 21px;
      color: #242633;
      display: flex;
      align-items: center;
      margin-left: 16px;

      &.no_date {
        margin-left: 24px;
      }
    }

    & .picker_group {
      margin-left: 24px;
      padding-top: 1px;

      & .row {
        display: flex;
        margin-bottom: 10px;

        & .datePicker {
          display: flex;
          background: #f5f6ff;
          border: 1px solid #d7ddf7;
          border-radius: 6px;
          width: 232px;
          height: 32px;
          align-items: center;

          &.hasError {
            border: 1px solid red;
          }

          & .name {
            width: 72px;
            height: 24px;
            background: #8697f0;
            border-radius: 6px;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 4px;
          }
        }
      }
    }
  }
}

.selected {
  circle {
    fill: #0026e5;
  }
}

.view_playlist_top_hat {
  margin: 24px auto 0;
  max-width: 785px;

  &.collapsed {
    max-width: 460px;
  }
}

.backToEditButton {
  position: absolute;
  bottom: 24px;
  left: 24px;

  .button_wrapper {
    width: 106px;
    height: 24px;
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: #505d9f;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #404d8c;
    }

    &:active {
      background: #1f2855;
    }

  }
}

/*TechnicalInfo.js*/

.technical_info_wrapper {
  display: flex;
  justify-content: space-between;
  color: #a3aacc;
  font-size: 12px;
  padding-bottom: 10px;
}

/*InputGroup.js*/

.input_group {
  padding-top: 18px;
}

.input_wrapper:focus-within {
  & .input_group_description {
    color: #939393;
  }
}

.dropdown_field:focus-within {
  & .input {
    border: 1px solid #ffc72c;
  }
}

.dropdown_separator {
  width: 240px;
  height: 1px;
  background: #c7cad1;
  margin: 7px 0;
}

.dropdown_remove_field {
  color: #c21c44;

  & svg {
    path {
      stroke: #c21c44;
    }
  }
}

.input_wrapper {
  & .invisible {
    height: 0;
    position: absolute;
    z-index: -1;
    border: 0;
    color: #ffffff;
  }

  & .warning_wrapper {
    height: 0;
    position: relative;

    & .warning {
      font-size: 12px;
      line-height: 18px;
      color: #c21c44;
      display: flex;
      align-items: center;

      & svg {
        margin-right: 5px;
      }
    }
  }
}

.input {
  background: #ffffff;
  border: 1px solid #ece2e2;
  box-sizing: border-box;
  border-radius: 6px;
  height: 32px;
  width: 342px;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  margin-top: 4px;
  padding-left: 8px;
  &:disabled {
    color: rgba(122, 105, 105, 0.5);
    cursor: not-allowed;
  }
  &.references {
    margin-top: 0;
  }

  &.category {
    width: 232px;
  }

  &.channel {
    width: 100%;
    margin-top: 8px;
  }

  &.channels {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #ece2e2;
    border-radius: 6px;
    height: 32px;
    width: 250px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #484343;
  }
  &.forselect {
    width: 293px;
  }
  &.duration {
    cursor: no-drop;
    user-select: none;
  }

  &.isNeedToWarning {
    border: 1px solid #c21c44;
  }
  &.disabled {
    color: rgba(122, 105, 105, 0.5);
    cursor: not-allowed;
  }
  &:focus {
    //border: 1px solid #8697F0;
  }
}

.no_edit {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  & .no_edit_svg {
    position: absolute;
    right: 0;
    margin-right: 7px;
    top: 7px;
  }
}

.dropdown_wrapper {
  position: relative;
  display: flex;
}

.channelInput.channelInput {
  flex-wrap: wrap;
  height: auto;
  min-height: 32px;
  padding: 2px;

  &.placeholder {
    padding-left: 6px;
    color: rgba(122, 105, 105, 0.5);
  }
}

.dropdown {
  position: absolute;
  width: 100%;
  height: fit-content;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 10;

  &.publishPlaylist {
    padding-top: 0;
    padding-bottom: 11px;
    max-height: 271px;
    .dropdown_row_wrapper {
      padding-left: 20px;
      .dropdown_icon {
        width: 20px;
        svg path {
          stroke: #484343;
        }
      }
      &:hover {
        background: #ffeebf;
      }
    }
    .dropdown_result {
      max-height: 165px;
      .dropdown_row_wrapper .dropdown_icon {
        width: 20px;
        margin-top: 4px;
      }
      .dropdown_row {
        padding-left: 8px;
      }
    }

    .dropdown_row.channel_row {
      line-height: 20px;
      padding-left: 8px;
      &.no_results {
        padding-left: 20px;
      }
    }
  }

  &.dropdown_for_image {
    position: absolute;
    height: fit-content;
    background: #ffffff;
    box-shadow:
      0 1px 4px rgba(0, 0, 0, 0.25),
      0 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding-top: 8px;
    width: 258px;
    padding-bottom: 8px;
    z-index: 10;
    font-size: 14px;
    line-height: 21px;
    color: #242633;
    top: 34px;
    right: 4px;
  }

  .dropdown_field {
    display: flex;
    width: 194px;
    height: 30px;
    padding: 5px 12px 5px 16px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */

    & svg {
      margin-right: 6px;
      height: 16px;
      width: 16px;
    }

    &:hover {
      background: #f5f6ff;
    }
  }

  .dropdown_search {
    width: auto;
    height: 32px;
    background: #ffffff;
    border: 1px solid #d7ddf7;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .search_icon_input {
      display: flex;
      align-items: center;
    }

    & .dropdown_search_icon {
      margin: 0 6px;
    }

    & .dropdown_close_icon {
      display: flex;
      align-items: center;
      margin: 0 6px;
      height: 24px;
      width: 24px;

      & svg:hover {
        background: #e8ebfa;
      }

      & svg {
        border-radius: 4px;
        padding: 4px;
        cursor: pointer;
        height: 24px;
        width: 24px;
      }
    }

    & input {
      width: 251px;
      border: 0;
      font-size: 14px;
      line-height: 21px;
      color: #242633;

      &::placeholder {
        color: rgba(122, 105, 105, 0.5);
      }
    }
  }
}

.dropdown_result {
  max-height: 520px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 8px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track-piece {
    display: none;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &.channel_result .dropdown_row_wrapper {
    padding-left: 20px;
    &.active {
      background: #ffeebf;
    }
    &:hover {
      background: #ffeebf;
    }
  }
}

.channel .dropdown_row_wrapper {
  display: flex;
  align-items: center;
  padding-left: 16px;
  cursor: pointer;

  &.active {
    background: #dde2f9;
  }

  &:hover {
    background: #e8ebfa;
  }
}

.dropdown_row {
  height: 32px;
  padding-top: 6px;
  padding-left: 16px;
  padding-right: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  white-space: nowrap;
  width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: #e8ebfa;
  }
}

.dropdown_row_wrapper.isDisabled {
  cursor: no-drop;
  opacity: 0.5;

  & .dropdown_row {
    cursor: no-drop;
  }

  &:hover {
    background-color: initial;
  }
}

.channel {
  .dropdown_row {
    user-select: none;

    &:hover {
      background: inherit;
    }
  }
}

.input_group_row {
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
}

.input_group_description {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 8px;
  color: #939393;

  &.settings {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #242633;
  }

  &.channels {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #939393;
    margin-bottom: 1px;
  }
  &.publishPlaylistDescription {
    font-weight: 500;
    font-size: 15px;
  }
}

.settings {
}

.input_group_description.required::after {
  content: " *";
  font-size: 12px;
  line-height: 18px;
  color: red;
}

/*AddComplementaryPlaylist.js*/
.additional.hidden {
  opacity: 0.5;
}

.additional_wrapper {
  padding-top: 40px;
}

.additional_option {
  padding-top: 6px;
  padding-left: 2px;
  padding-bottom: 44px;
}

.additional_info {
  display: flex;
  cursor: pointer;
  position: relative;

  &.hoverable {
    &:hover .hideSvg {
      visibility: visible;
    }
  }

  .hideSvg {
    position: absolute;
    right: 10px;
    visibility: hidden;
  }

  .hiddenSvg {
    visibility: visible;
  }
}

.additional_info svg {
  margin-right: 13px;
}

.additional_head {
  margin-top: 2px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #242633;

  &.no_description_balancer {
    margin-bottom: 11px;
  }
}

.additional_description {
  margin-top: 10px;
  font-size: 12px;
  line-height: 18px;
  color: #a3aacc;
}

.additional_line {
  background: #e8ebfa;
  width: 700px;
  height: 1px;
  margin-top: 8px;
  margin-bottom: 18px;
}

.additional_dropdown {
  cursor: pointer;
  position: absolute;
  left: -46px;
  top: -9px;
  width: 42px;
  height: 42px;
  padding: 10px;

  & path {
    stroke: #a3aacc;
  }
}

.additional_dropdown.isOpen {
  transform: rotate(90deg);
}

.additional_add_block_icon {
  padding: 8px;
}

.additional_add_block {
  display: flex;
  line-height: 24px;
  color: #0026e5;
  font-size: 12px;
  width: 114px;
  height: 32px;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.additional_complementary {
  //width: 132px;
  height: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #0026e5;
  padding: 10px;
  border: 1px solid #d7ddf7;
  display: flex;
  align-items: center;
  border-radius: 6px;
  width: fit-content;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 12px;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background: #e8ebfa;
  }
}

.additional_add_block:hover {
  background: #e8ebfa;
  border-radius: 18px;
}

/*PlaylistPages.js*/
.playlist_pages {
  padding-top: 40px;

  .playlist_pages_info {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    .playlist_pages_text {
      margin-top: 4px;
      display: flex;
    }
  }

  .playlist_pages_header {
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #242633;
  }

  .playlist_pages_counter {
    font-size: 16px;
    line-height: 26px;
    color: #a3aacc;
    margin-left: 16px;
  }

  .playlist_pages_builder {
    border: 1px solid #d7ddf7;
    box-sizing: border-box;
    border-radius: 6px;
    color: #0026e5;
    font-size: 12px;
    line-height: 12px;
    width: 100px;
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;

    & svg {
      margin: 0 5px;
    }

    &:hover {
      background: #e8ebfa;
    }
  }

  .page {
    border-bottom: 1px solid #d7ddf7;
    padding-top: 12px;
    padding-bottom: 15px;
    margin-bottom: 12px;
    color: #242633;
    display: flex;
    position: relative;

    &_arrow {
      position: absolute;
      right: 1px;
      font-size: 14px;
      line-height: 24px;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        background: #e8ebfa;
      }
    }

    &_durationTimePage {
      right: 10px;
      font-size: 12px;
      line-height: 18px;
      color: #a3aacc;
    }

    &_info {
      max-width: 600px;
    }

    &_title {
      display: flex;
      font-size: 14px;
      line-height: 24px;
      align-items: center;
    }

    &_description {
      padding-top: 7px;
      font-size: 12px;
      line-height: 18px;
      color: #a3aacc;
    }

    &_dropdown.isOpen {
      transform: rotate(90deg);
    }

    & svg {
      width: 24px;
      height: 24px;
      //margin-top: 1px;
      //margin-right: 13px;
    }

    & .check_icon {
      flex-shrink: 0;
      position: absolute;
      left: -37px;
    }

    & .ellipsis_wrapper {
      @include ellipsis;
      font-size: 16px;
      line-height: 20px;
      color: #242633;
    }
  }
}

.playlist_pages_start {
  width: 700px;
  height: 48px;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #0026e5;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 24px;
  padding-left: 11px;

  &:hover {
    background: #e8ebfa;
  }
}

.playlist_pages_start svg {
  margin-right: 13px;
}

.input_title {
  height: auto;
  display: block;
  width: 100%;
  //max-width: 100%;
  //min-width: 100%;
  overflow: hidden;
  resize: none;
  border: none;
  background: none;
  padding: 18px 0 0 0;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  line-height: 40px;
  color: #242633;

  &::placeholder {
    color: rgba(122, 105, 105, 0.5);
  }
}

.chevron {
  padding-left: 15px;
}

.text {
  max-width: 100%;
  width: 100%;
  min-height: 10px;
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: rgb(55, 53, 47);
  padding: 3px 2px;
}

.description.hidden {
  display: none;
}

.description {
  margin-top: 15px;
  background-color: inherit;
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
  resize: none;
  border: none;
  color: #939393;
  font-family: "RobotoFlex", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  &::placeholder {
    color: rgba(122, 105, 105, 0.5);
  }
}

.note {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #596080;
}

.eee {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.builder_wrapper {
  display: flex;
  flex-direction: column;
}

.content_main_section {
  position: relative;
  width: 100%;
  top: 114px;
  //padding-left: 20px;
  &.scrollCompensation {
    left: 0;
  }

  & .content {
    margin: 0 auto;
    max-width: 460px;
  }

  &.sideBarOpened {
    //padding-left: 20px;
  }
}

.right_side_section {
  width: 0;
  transition: all 0.5s cubic-bezier(0.19, 0.45, 0.8, 0.43);

  &.active {
    width: 400px;
  }
}

.hat_wrapper {
  &:hover .top_controls {
    visibility: visible;
  }
}

.top_controls {
  visibility: visible;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;

  .button {
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    height: 24px;
    color: #596080;
    display: flex;
    align-items: center;
    border-radius: 18px;
    margin-right: 13px;

    &:hover {
      background-color: #e8ebfa;
    }

    & svg {
      margin-left: 8px;
    }

    & > * {
      margin-right: 8px;
    }
  }
}

.wrapper_cover_image {
  position: relative;
  transition: margin-bottom ease-in-out 0.3s;

  &.expand_edit_buttons {
    margin-bottom: 40px;
  }

  .block_image_wpapper {
    position: relative;
    max-height: 236px;
    border: 1px solid #d7ddf7;
    border-radius: 8px;
    box-sizing: content-box;

    &.block_image_wpapper_large {
      max-height: 405px;
    }

    & img {
      position: relative;
    }

    &.modal_balancer {
      max-height: 172px;
      border-radius: 6px;
    }
  }

  .block_image {
    max-height: 236px;
    overflow: hidden;
    border-radius: 7px;
  }

  .block_image_large {
    max-height: 405px;
  }

  & .save_option {
    position: absolute;
    bottom: -40px;
    right: 0;
    display: flex;
    z-index: 1;

    & .cancel {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 89px;
      height: 32px;
      border-radius: 6px;
      margin-right: 16px;
    }

    & .save {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 94px;
      height: 32px;
      border: 1px solid #0026e5;
      border-radius: 6px;
    }

    & div:hover {
      background: #e8ebfa;
      cursor: pointer;
    }
  }

  & .move_option {
    position: absolute;
    //top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 32px;
    right: 98px;
    top: 102px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 6px;
    z-index: 1;
    font-size: 12px;
    text-transform: uppercase;
    color: #404d8c;
  }

  .drag_handler {
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
    cursor: grab;
  }

  .drag {
    cursor: grabbing;
  }
}

.block_image__block_edit {
  position: absolute;
  top: 15px;
  right: 11px;
  z-index: 1;
  cursor: pointer;
}

.block_image:hover .block_image__block_edit {
  //display: flex;
}

.block_image__delete {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 6px;
  border-right: 1px solid rgba(55, 53, 47, 1);
  font-weight: 500;
  color: rgba(55, 53, 47, 1);
  fill: rgba(55, 53, 47, 1);
  background: rgba(169, 169, 169, 0.8);
}

.block_image__change {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 6px;
  border-right: 1px solid rgba(55, 53, 47, 1);
  font-weight: 500;
  color: rgba(55, 53, 47, 1);
  background: rgba(169, 169, 169, 0.8);
}

.input_description {
  border: none;
  background: none;
  width: 100%;
  display: block;
  resize: none;
  padding: 35px 0 0 0;
  font-family: "RobotoFlex", serif;
  font-style: normal;
  font-size: 20px;
}

.add_image {
  opacity: 0;
  display: flex;
  width: fit-content;
  cursor: pointer;
}

.add_image:hover {
  background: #b5b6ba;
}

.add_image__text {
  padding-left: 5px;
}

.add_image__icon {
  width: 15px;
}

.title:hover .add_image {
  opacity: 0.2;
}

.image {
  width: 100%;
}

.image_carousel {
  position: absolute;
  width: 342px;
  object-fit: cover;
  overflow-x: hidden;
}

.image_cover {
  width: 100%;
  border-radius: 7px;
}

.provisional_button {
  margin-top: 100px;
}

.section {
}

.carousel_wrapper {
  position: relative;
  width: 700px;

  .carousel {
    overflow: hidden;
    width: 700px;
    position: relative;
    height: 334px;

    .content {
      display: flex;
      transition-property: left;
      transition-duration: 0.4s;
      position: absolute;
    }
  }

  .carousel_nav {
    position: absolute;
    top: 180px;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background: #e8ebfa;
    }

    &.left {
      left: -50px;
    }

    &.right {
      right: -50px;
      transform: rotate(180deg);
    }

    &.disable {
      cursor: no-drop;
      opacity: 0.4;
    }

    &.disable:hover {
      background: none;
    }
  }

  .progress_line_wrap {
    position: relative;
    width: 700px;
    height: 1px;
    padding: 8px 0;
    cursor: pointer;
  }

  .progress_line {
    width: 700px;
    height: 1px;
    background: #e8ebfa;
  }

  .progress_line_blue {
    position: absolute;
    height: 1px;
    top: 8px;
    width: 10px;
    background: #0026e5;
    transition-property: width;
    transition-duration: 0.4s;
  }
}

.page_card {
  /*width: 171px;*/
  border-color: blue;
  margin: 0 16px 20px 0;
  width: 342px;
  height: 323px;
  position: relative;

  .close_wrap {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 16px;
    top: 16px;
    z-index: 100;
    border-radius: 4px;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;

    &:hover {
      background: #e8ebfa;
    }
  }

  &:hover {
    .close_wrap {
      visibility: visible;
    }
  }
}

.preview_card:hover {
  border: 1px solid #a3aacc;
}

.preview_card {
  width: 342px;
  height: 323px;
  background-color: white;
  border: 1px solid #d7ddf7;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  z-index: 25;
}

.wrapper_pro_card {
  overflow: hidden;
  width: 342px;
}

.wrapper_card {
  width: 342px;

  overflow: hidden;
}

.image_wrapper2_card {
  height: 124px;
  position: relative;
  overflow: hidden;
}

.content_card {
  padding: 4px 14px;
}

.content_text_card {
  margin-bottom: 9px;
  height: 88px;
}

.title_card {
  overflow: hidden;
  padding-top: 4px;
  resize: none;
  min-height: 40px;
  border: none;
  background: none;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  color: #242633;
  line-height: 28px;
}

.description_card {
  padding-top: 2px;
  font-size: 14px;
  color: #242633;
  height: 30px;
  font-family: "RobotoFlex", sans-serif;
  font-weight: 400;
  line-height: 21px;
  @include ellipsis;
}

.image_card {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 342px;
  max-height: 124px;
  object-fit: cover;
  overflow-x: hidden;
}

.author {
  font-size: 12px;
  margin-top: 11px;

  color: #596080;
  display: flex;
  align-items: center;
  width: 133px;
  @include ellipsis;

  & img {
    margin-right: 9px;
    width: 24px;
    height: 24px;
  }
}

.about {
  display: flex;
  margin-top: 17px;
  justify-content: space-between;

  .price {
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    line-height: 30px;
    color: #242633;
  }

  .time {
    padding-left: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #a3aacc;
    @include ellipsis;

    img {
      margin-right: 9px;
    }
  }
}

.settings_top_wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100vh;
  width: 100%;
  max-width: 483px;
  background: white;
  transition: width ease-in-out 0.45s;

  & .thickBorder {
    width: 4px;
    height: 100%;
    background: #e4e7f7;

    &:hover {
      background: #7f849f;
    }
  }

  & .wrapper {
    width: 100%;
    height: 100%;
    margin: 20px 0 0 0;
    overflow-y: auto;

    & .header_wrapper {
      margin: 0 10px 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-basis: 170px;

      .title {
        font-style: normal;
        font-weight: 600;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        line-height: 24px;
      }

      .controls {
        .button_wrapper {
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          border: 1px solid transparent;
          background: white;
          cursor: pointer;

          &:hover,
          &.active {
            background: #e8ebfa;
          }
        }
      }
    }
  }
}

.isPlaylist.isPlaylist {
  margin: 24px 11px 0 0;

  &.hashtags {
    margin: 14px 11px 0 0;
  }
}

.item_wrapper {
  &.isComponentDescription.isComponentDescription {
    margin: 0;

    .item_container {
      border: 1px solid #ece2e2;
    }
  }

  margin: 24px 11px 0 16px;

  &.isExpanded {
    margin: 24px 32px 0 16px;
  }

  &.isPage {
    margin: 24px 11px 0 0;
  }

  &.createComponent.isCreateFromLibrary {
    margin: 0 12px 0 12px;
  }

  &.references {
    margin-top: 22px;
  }

  &.availability {
    margin-top: 24px;
  }

  &.channel {
    margin-top: 32px;
  }

  &.hashtags {
    margin: 14px 11px 0 0;
  }

  &.tags {
    margin-top: 22px;
  }

  &.comments {
    margin-top: 32px;
  }

  &.remix {
    margin-top: 6px;
    padding-bottom: 60px;
  }

  &.noMargins {
    margin-top: 0;
  }

  position: relative;

  &:focus-within .item_title.hashtags {
    //color: #8697F0;
  }

  .item_title_container {
    margin: 0 22px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.toggler {
      margin: 0;
    }

    &.selectColumn {
      z-index: 1;
    }
  }

  .item_title {
    font-family: "RobotoFlex", sans-serif;
    margin: 0 0 8px 0;
    color: #d3c8c8;
    text-transform: uppercase;

    &.availability {
      margin-bottom: 8px;
    }

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    //color: #242633;

    &.noDescription.noDescription {
      margin-bottom: 11px;
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
    }

    & span.hint {
      font-family: "RobotoFlex", sans-serif;
      padding-left: 2px;
      color: #b30e00;
    }

    &.toggler {
      margin: 0 0 8px 0;
    }

    //&.hashtags{
    //    color: #8697F0;
    //}
    &.hashtags {
      margin-bottom: 4px;
    }
  }

  .item_subtitle {
    height: 36px;
    width: 315px;
    left: 0;
    right: 64px;
    top: 24px;
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #596080;
  }

  .item_description {
    &.hashtags {
      font-family: "RobotoFlex", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #596080;
      margin-bottom: 8px;
    }
  }

  .item_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid #ece2e2;
    border-radius: 6px;
    height: 32px;
    color: #7a696980;

    &.text {
      max-width: 232px;
    }

    &.hashtags {
      max-width: 100%;
      height: auto;
      width: 418px;

      &.isExpanded {
        max-width: initial;
        width: 100%;
      }

      overflow: hidden;

      &:focus-within {
        border: 1px solid #ffc72c;
      }
    }

    &.datepicker {
      max-width: 100%;
      height: auto;
      border: none;
    }

    & .input_text_wrapper {
      width: 232px;
      height: 30px;
      padding: 2px 4px;

      & input {
        width: 100%;
        height: 100%;
        border: none;
        font-family: "RobotoFlex", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;

        &::placeholder {
          font-family: "RobotoFlex", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: rgba(122, 105, 105, 0.5);
        }
      }
    }

    & .input_datepicker_wrapper {
      border: none;
      width: 100%;

      .picker_controls_wrapper {
        height: 32px;
        width: 232px;
        border: 1px solid #ffc72c;
        border-radius: 6px;
        background: #f5f6ff;
        margin: 0 0 12px 0;

        .controls_container {
          padding: 2px;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .controls_item {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 72px;
            height: 24px;
            border-radius: 6px;
            font-family: "RobotoFlex", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: #596080;

            &.active {
              background: #8697f0;
              color: #ffffff;
            }
          }
        }
      }

      .picker_input_wrapper {
        padding-left: 2px;
        margin-bottom: 10px;

        & .material {
          outline: 2px solid #b30e00;
        }

        & .wojtekmaj {
          outline: 2px solid darkgreen;
        }

        & .flatpicker {
          outline: 2px solid silver;
        }

        & .hackerone {
          outline: 2px solid turquoise;
        }

        & .item_container {
          & .input_text_wrapper {
          }

          & .controls {
            display: flex;
            justify-content: center;
            align-items: center;

            .button_wrapper {
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 6px;
              border: 1px solid transparent;
              background: white;
              cursor: pointer;

              &:hover,
              &.active {
                background: #e8ebfa;
              }
            }
          }
        }
      }
    }

    .controls {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 4px;

      .button_wrapper {
        width: 20px;
        height: 20px;
        cursor: pointer;
        border-radius: 4px;

        &:hover,
        &.active {
          background: #e8ebfa;
        }
      }
    }
  }
}

.subtitle_and_dropdown {
  display: flex;
  width: 429px;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.item_title_container {
  &.toggler {
    &.modal {
      font-family: "RobotoFlex", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #242633;
      margin-top: 16px;
      width: 180px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 6px;
    }
  }
}

.item_title {
  &.toggler {
    &.modal {
      width: 144px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.item_title_container {
  position: relative;
  margin: 0 22px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.toggler {
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #242633;
    //margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0 3px 0;
  }
}

.toggler {
  &.modal {
    width: 144px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.item_subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  color: #343434;

  &.channel {
    display: flex;
    justify-content: space-between;
  }
}

.category_wrapper,
.lock_wrapper {
  position: relative;
  width: 0;
  height: 0;
}

.category_label {
  z-index: 1;
  position: absolute;
  width: max-content;
  padding: 6px;
  height: 24px;
  left: 16px;
  top: 132px;
  background: #ffffff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #404d8c;

  &.carousel_balancer {
    top: 87px;
  }
}

.datepicker_button {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  &:hover {
    background: #e8ebfa;
  }
}

.input_hashtags_wrapper {
  width: 100%;
  min-height: 66px;
  max-height: 145px;
  height: auto;
  padding: 8px 8px;
  display: flex;
  flex-direction: row;
  &.empty {
    padding: 1px 4px;
  }

  & .hashtags_container {
    min-width: 363px;
    max-width: 451px;
    column-gap: 8px;
    row-gap: 7px;
    &.isExpanded {
      width: 100%;
    }
    &.forComponentDescription {
      min-width: initial;
    }

    display: flex;
    flex-flow: row wrap;
    width: auto;

    &:focus-within {
    }

    .text {
      max-width: 110px;
      width: auto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #202124;
      @include ellipsis;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 6px;
    }

    input,
    input:active,
    input:focus {
      width: 240px;
      height: 26px;
      border: none;
      font-size: 14px;
      min-width: 10px;
      max-width: 240px;
      font-family: "RobotoFlex", sans-serif;
      display: block;

      &::placeholder {
        color: rgba(122, 105, 105, 0.5);
      }

      &.hidden {
        display: none;
      }
    }

    input::placeholder {
      font-family: "RobotoFlex", sans-serif;
      color: rgba(122, 105, 105, 0.5);
    }

    .emptyInput {
      height: 0;
      width: 0;
    }

    textarea,
    textarea:active,
    textarea:focus {
      //height: 24px;
      border: none;
      width: 100%;
      height: 100%;
      resize: none;
    }
  }
}

.hashtag {
  cursor: pointer;
  padding: 0 8px;
  user-select: none;
  margin: 1px 3px;
  max-width: 141px;
  height: 24px;
  background-color: white;
  border-radius: 2px;
  border: 1px solid #ece2e2;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &:hover {
    //background: #e8ebfa;
    background: #ffeebf;
  }

  & .text {
    max-width: 110px;
    width: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #202124;
    @include ellipsis;
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 6px;
  }
}

.wrapperTag {
  width: 160px;
}

.tag {
  cursor: pointer;
  user-select: none;
  padding: 0 8px 0 8px;
  //margin: 1px 3px;
  max-width: 141px;
  width: fit-content;
  height: 24px;
  background-color: #e8ebfa;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &.ffffff {
    border: 1px solid #d7ddf7;
  }

  & .text {
    max-width: 110px;
    width: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #202124;
    @include ellipsis;
    padding: 0;
  }

  & .textHeader {
    max-width: 110px;
    width: auto;
    color: #202124;
    @include ellipsis;
  }

  & .icon.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 3px;
    padding: 3px;
  }

  &.initial {
    background-color: white;
    border: 1px solid #d7ddf7;

    &:hover {
      background-color: #dde1f5;
    }
  }

  &.grey1 {
    background-color: rgba(163, 170, 204, 0.2);

    &:hover {
      background-color: rgba(163, 170, 204, 0.5);
    }
  }

  &.grey2 {
    background-color: rgba(89, 96, 128, 0.2);

    &:hover {
      background-color: rgba(89, 96, 128, 0.5);
    }
  }

  &.grey3 {
    background-color: rgba(36, 38, 51, 0.2);

    &:hover {
      background-color: rgba(36, 38, 51, 0.5);
    }
  }

  &.blue1 {
    background-color: rgba(68, 136, 211, 0.2);

    &:hover {
      background-color: rgba(68, 136, 211, 0.5);
    }
  }

  &.blue2 {
    background-color: rgba(96, 94, 236, 0.2);

    &:hover {
      background-color: rgba(96, 94, 236, 0.5);
    }
  }

  &.violet {
    background-color: rgba(104, 38, 188, 0.2);

    &:hover {
      background-color: rgba(104, 38, 188, 0.5);
    }
  }

  &.pink {
    background-color: rgba(174, 69, 202, 0.2);

    &:hover {
      background-color: rgba(174, 69, 202, 0.5);
    }
  }

  &.green1 {
    background-color: rgba(81, 171, 159, 0.2);

    &:hover {
      background-color: rgba(81, 171, 159, 0.5);
    }
  }

  &.green2 {
    background-color: rgba(59, 118, 105, 0.2);

    &:hover {
      background-color: rgba(59, 118, 105, 0.5);
    }
  }

  &.green3 {
    background-color: rgba(67, 175, 65, 0.2);

    &:hover {
      background-color: rgba(67, 175, 65, 0.5);
    }
  }

  &.brown1 {
    background-color: rgba(134, 123, 98, 0.2);

    &:hover {
      background-color: rgba(134, 123, 98, 0.5);
    }
  }

  &.brown2 {
    background-color: rgba(196, 79, 93, 0.2);

    &:hover {
      background-color: rgba(196, 79, 93, 0.5);
    }
  }

  &.brown3 {
    background-color: rgba(162, 103, 101, 0.2);

    &:hover {
      background-color: rgba(162, 103, 101, 0.5);
    }
  }

  &.orange {
    background-color: rgba(219, 135, 74, 0.2);

    &:hover {
      background-color: rgba(219, 135, 74, 0.5);
    }
  }

  &.yellow {
    background-color: rgba(240, 196, 76, 0.2);

    &:hover {
      background-color: rgba(240, 196, 76, 0.5);
    }
  }
}

.tags_input {
  background-color: white;
  border-radius: 16px;
  height: 18px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  font-size: 12px;
  line-height: 12px;

  & input {
    height: 18px;
  }
}

.tags_input_container {
}

.not_editable {
  pointer-events: none;
}

.toggler_container {
  width: 28px;
  height: 13px;
  &.channel {
    position: relative;
    top: 3px;
  }
}

.coreInfo_wrapper {
  margin-bottom: 40px;
  position: relative;
}

.meta_section_wrapper {
  border-radius: 6px;
  width: 700px;
  height: 120px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 5px;

  .container {
    margin-bottom: 15px;
    display: flex;

    &.modal_balancer {
      margin-bottom: 8px;
    }
  }

  &.modal_balancer {
    padding-left: 0;
    width: 528px;
  }
}

.label {
  height: 32px;
  display: flex;
  align-items: center;
  width: 135px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #a3aacc;

  svg {
    margin-right: 13px;
  }
}

.value {
  height: 32px;
  @include ellipsis;
  width: 208px;
  display: inline-block;
  padding-top: 8px;
  align-items: center;
  color: #242633;

  &.small {
    width: 108px;
  }
}

.start_playlist_section {
  border-radius: 6px;
  min-height: 104px;
  background: #f5f6ff;
  width: 700px;
  height: max-content;
  margin-bottom: 28px;
  display: flex;

  &.start_state {
    padding: 23px 17px 23px 16px;
    min-height: 83px;
    justify-content: space-between;
    align-items: flex-end;
  }

  &.continue_state {
    flex-direction: column;
    padding: 11px 16px;
  }

  .price {
    font-style: normal;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-right: 16px;
  }

  .text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #505d9f;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 9px 6px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #0026e5;
    border-radius: 4px;
    border: 1px solid #0026e5;
    width: max-content;
    max-width: 150px;
    min-width: 80px;
    white-space: nowrap;
    align-self: center;
    cursor: pointer;

    &:hover {
      background-color: #d7ddf7;
    }
  }

  & .total_progress_bar {
    position: relative;
    top: 8px;
    display: flex;
    margin-bottom: 16px;
  }
}

.info {
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: space-between;
}

.page_title_text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0026e5;
  width: 550px;
  display: flex;

  & .page_counter {
    letter-spacing: 4px;
    font-size: 17px;
  }

  & .next_page_title {
    @include ellipsis;
  }
}

.viewed_progress {
  width: 100%;
  flex: 1;

  .bar {
    width: 100%;
    background-color: #d7ddf7;
    height: 2px;
    margin-bottom: 8px;

    &.filled {
    }
  }

  .percentage {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #a3aacc;
  }
}

.view_mode_balancer {
  left: 0;
}

.placeholder {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: rgba(122, 105, 105, 0.5);
}

input::placeholder,
textarea::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(122, 105, 105, 0.5);
}

.upload_wrap {
  position: absolute;
  right: 50%;
  top: 50%;
  z-index: 30;

  & svg {
    transform: translate(-50%, -50%);
  }
}

.sideBarWidth {
}

textarea.chDescription {
  resize: none;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #ece2e2;
  border-radius: 8px;
  font-family: "RobotoFlex";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  height: 136px;
  width: 520px;

  &::placeholder {
    color: rgba(122, 105, 105, 0.5);
  }
}

.view_builder_wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: calc(100vh - 64px);
  overflow-y: scroll;
  overflow-x: hidden;

  &.isShared {
    height: 100vh;
    overflow: hidden;
  }
}

.view_table_of_contents_wrapper {
}

.view_zoom_wrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;

  .item {
    width: 32px;
    height: 32px;
    margin: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.main_content {
  background: #f5f6ff;
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100%;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f6ff;
  }

  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 8px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track-piece {
    display: none;
    background-color: #f5f6ff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}


.zoom {
  transform-origin: top center;
  max-height: calc(100vh - 64px);
}

.badge_container {
  margin-left: 3px;
  margin-right: 6px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  & svg {
    width: 20px;
    height: 20px;
  }

  & svg path {
    stroke: #585858;
  }

  & svg rect {
    fill: #ffffff;
  }

  & .item {
    width: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 2px 4px;
    font-size: 10px;
    color: #242633;
    white-space: nowrap;

    &.coAdmin {
      background-color: rgba(237, 124, 124, 0.33);
    }

    &.viewer {
      background-color: rgba(165, 237, 124, 0.33);
    }

    &.contributor {
      background-color: #20b2aa57;
    }

    &.admin {
      background-color: #adbbf7;
    }
  }
}

.item_wrapper.createComponent {
  .item_title {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #596080;
  }

  .item_container {
    min-width: 235px;
    max-width: 235px;
  }

  .hashtags_container {
    min-width: 225px;
    max-width: 225px;
  }
}

.item_wrapper.createComponent.isCreateFromLibrary {
  margin: 0 9px 0 19px;

  .item_container {
    min-width: initial;
    max-width: initial;
    min-height: 42px;

    .hashtags_container {
      min-width: initial;
      max-width: initial;

      input {
        height: 24px;
        width: 195px;
      }
    }
    &.for_create_block {
      width: 616px;
      height: 68px;
      top: 11px;
      left: -39px;
      position: relative;
      overflow: visible;
    }
    .input_hashtags_wrapper {
      min-height: initial;
      max-height: initial;
      padding: 7px 8px;
    }
  }
  & .for_create_link {
    margin-left: -20px;
    align-items: initial;
    border: 1px solid #7a696980;
    &.active {
      border: 1px solid #ffc72c;
    }
    .input_hashtags_wrapper {
      min-height: 68px;
    }
  }
}

.cross_wrapper {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 4px;

  & svg path {
    stroke: #596080;
  }
}

.flex_wrapper {
  display: flex;
}

.item_title_container .item_title.toggler.channels {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #939393;
}
