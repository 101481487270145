@import "../../../mixins";

.plusIcon {
  position: absolute;
  top: 50%;
  left: 76px;
  flex-direction: column;
  align-items: center;
  background-color: #ffe9ad;
  & .line {
    background-color: #ffc72c;
    width: 2px;
    height: calc(100% + 14px);
  }
  & .plus {
    transform: translateY(-50%);
    padding: 4px 4px;
    width: 24px;
    height: 24px;
    z-index: 1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    &.bottom {
      top: initial;
      bottom: -19px;
    }
    &:hover {
      background: rgba(126, 122, 133, 0.04);
    }
    & svg path {
      stroke: #484343;
    }
    &.plus_viewer {
      //visibility: hidden;
    }
  }
}


// Common styles
.content {
  padding-left: 50%;
  max-width: 856px;
  height: 100%;
}
.create_new_page {
  position: relative;
  border: 1px solid #484343;
  box-sizing: border-box;
  border-radius: 8px;
  width: 114px;
  height: 167px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & svg {
    margin-bottom: 10px;
    & path {
      stroke: #484343;
    }
  }
  & .icon_wrapper {
    width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #484343;
    & svg {
      margin-bottom: 0;
      margin-right: 7px;
    }
  }
  .separator {
  }

  &.hoverable {
    border: none;
    background-color: #ffeebf;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23484343FF' stroke-width='2' stroke-dasharray='7%2c 9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 8px;
    & svg path {
      color: #484343;
    }
  }
}

// Root styles
.wrapper {
  width: 100vw;
  transform: translateX(-50%);
  display: flex;
  height: 100%;
  flex-direction: column;
}

.builder__wrapper {
  background: #f6f6f5;
  font-family: "RobotoFlex", sans-serif;
  padding: 0;
  width: 100%;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f6ff;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 8px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background-color: #f5f6ff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  & h1, h2, h3 {
    font-family: Areal, sans-serif;
  }
}

.pages__section {
  position: relative;
  top: 35px;
  width: 100%;
  &.shift {
    left: 0;
  }
  &.full_width {
    width: 100%;
  }
}

.all_items_wrapper {
  min-width: 100%;
  position: relative;
  &.canvas {
    position: relative;
    &.collapsed {
      max-width: 700px;
    }
  }
}

.pages_wrapper,
.add_page_wrapper {
  position: relative;
  width: 100%;
  top: 0;
  height: 100%;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100vh - 60px);

  &.scrollable {
    justify-content: flex-start;
  }
  .next_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    height: 0;
    top: 0;
    transition: height 0.3s;
    &.viewMode {
      width: 300px;
      top: -9px;
    }
    &.isCompressView {

    }
    &.active {
      height: 52px;
    }
    &.bottom {
      top: initial;
      position: absolute;
      bottom: -129px;
      height: 130px;
    }
    &.active.bottom {
      height: 70px;
    }
    &.canvas {
      position: absolute;
      top: -40px;
      height: 16px;
    }
  }
  .next_item_container {
    position: absolute;
    top: 2px;

    &.no_elements {
      position: absolute;
      top: -12px;
    }
    &.collapsed {
      top: 16px;
    }
    &.hide {
      display: none;
    }
  }
  .next_item_branch {
    position: relative;
    width: 1px;
    min-height: 14px;
    &:not(.collapsed) {
      background: #d7ddf7;
    }
    &.collapsed {
      background: initial;
    }
    &.small {
      min-height: 14px;
    }
    &.last_item {
      margin-left: 5px;
    }
    &.hide {
    }
    &.hide .line {
      visibility: visible;
    }
    &.canvas {
      min-height: 38px;
      &.collapsed {
        min-height: 24px;
      }
      &.hide {
        display: none;
      }
    }
  }

  & .page_container {
    position: relative;
    margin: 0 auto;
    &.zActive {
      z-index: 10;
    }
    &.with_caption {
      padding-bottom: unset;
    }
    &.canvas {
      min-width: 700px;
      &.collapsed {
        min-width: initial;
        margin-bottom: 24px;
        margin-top: 24px;
        max-width: 512px;
      }
    }
  }
}

.pages_wrapper.side_scroll {
  align-items: flex-start;
}

.page_overlay {
  position: relative;
  &:hover .cross_action {
    visibility: visible;
  }

  & .cross_action {
    z-index: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    position: absolute;
    background: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    top: 5px;
    right: 25px;
    visibility: hidden;
    & svg path {
      stroke: #596080;
    }
    &:hover {
      background: #e8ebfa;
    }
  }
}

.selection_border {
  background: #c5ceff88;
  position: fixed;
  z-index: 99;
}

.add_page_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.add_page {
  //margin-left: -85px;
  cursor: pointer;
}

.icon_page_40 {
  width: 48px;
}

.icon_add_page {
  width: 110px;
  margin-top: 8px;
}

.dragLine_wrap {
  padding-top: 60px;
  padding-bottom: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: padding-top 0.3s;

  &.hasHoverDrag{
    padding-top: 80px;
  }
}

.dragLine_line {
  position: absolute;
  top: calc(50% - 5px);
  z-index: 1;
  width: 1020px;
  height: 1px;
  background-color: #7b00cf;
  &::before {
    content: '';
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 1px solid #7b00cf;
    display: flex;
    position: absolute;
    left: -11px;
    top: -5px;
  }
  &.first {
    top: -32px;
  }
  &.last {
    top: 30px;
  }
}

.counter {
  height: 18px;
  padding-top: 21px;
  padding-left: 15px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  line-height: 18px;
  text-transform: uppercase;
  color: #a3aacc;
}

//Header styles
.header__wrapper {
  height: 7vh;
  position: relative;
  z-index: 2;
  margin-right: 16px;
  margin-top: 15px;
  padding-left: 10px;
}
.builderHeader__wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}
.not_editable {
  pointer-events: none;
}
.headingMenu__options {
  display: flex;
  justify-content: flex-end;
  font-style: normal;
  font-weight: normal;
  font-family: "RobotoFlex", sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  letter-spacing: 1px;
  & .icon {
    cursor: pointer;
    &:hover svg rect {
      fill: #e8ebfa;
    }

    &.active svg rect {
      fill: #e8ebfa;
    }
  }
}

.item {
  display: flex;
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: relative;
  margin-left: 16px;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 6px;
  background: #e8ebfa;

  & svg path {
    stroke: #596080;
    fill: #fff;
  }
  &.active svg path {
    stroke: #596080;
    fill: #dde2f9;
  }

  &.active {
    background: #dde2f9;
  }

  .disabled {
    cursor: no-drop;
    background: none;
  }

  :hover .disabled {
    background: none;
    cursor: no-drop;
  }

  :hover.disabled svg path {
    stroke: #a2a7b2;
    fill: #fff;
  }

  .disabled svg path {
    stroke: #a2a7b2;
  }
  :hover.disabled svg {
    background: none;
  }
}

.path {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.text_container {
  display: flex;
  align-items: center;
  margin-right: 14px;
}

.text_wrapper {
  @include ellipsis;
  max-width: 272px;
  &.small {
    min-width: 80px;
    max-width: 100px;
  }
  &.dates {
    max-width: 333px;
  }
}

.text_svg_wrapper {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

// Footer

.footer__wrapper {
  position: fixed;
  bottom: 0;
  right: 16px;
  width: 128px;
  margin-left: auto;
  z-index: 6;
  &.moveLeft {
    right: 550px;
  }
  &.bottomShift {
    bottom: 44px;
  }
}

.footer__section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  height: 64px;
  .footer__item {
    margin-left: 0;
    background: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    &:hover {
      background: rgb(255, 238, 191);
    }
  }
}

.dragInvisibleDiv {
  z-index: 26;
  position: absolute;
  background: rgba(100, 100, 160, 0.8);
  border-radius: 10px;
  width: 100%;
  height: 219px;
}
.invisibleParent {
  position: relative;
}
.dragInvisibleDivPlus {
  z-index: 26;
  position: absolute;
  background: rgba(250, 100, 160, 0.8);
  border-radius: 10px;
  width: 100%;
  height: 95px;
}

// RightSideBar styles
.border {
  width: 4px;
  height: 100vh;
  background: #ffeebf;
  cursor: pointer;
  &:hover {
    background: #9192ac;
  }
}
.rightBar__wrapper {
  display: flex;
  flex-direction: row;
  width: 582px;
  visibility: visible;
  .rightBar__section {
    position: relative;
    top: 0;
    height: 100vh;
    width: 100%;
    background: #ffffff;
    flex-shrink: 2;
  }

  &.notActive {
    visibility: hidden;
    & div {
      visibility: hidden;
    }
  }
}

.create_new_element.expanded_content {
  max-width: 1200px;
  width: calc(100vw - 430px);

  &.isCut {
    width: calc(100vw - 765px);
  }
}

.create_new_element {
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 52px;
  border: 1px solid #ece2e2;
  filter: drop-shadow(0px 1px 4px rgba(122, 105, 105, 0.1));
  border-radius: 8px;
  &:hover {
    border: 1px solid #FFC72C;
    background-color: #ffeebf;
  }
  &.hoverable {
    border: 1px solid #FFC72C;
    background-color: #ffeebf;
  }
  & svg {
    margin-bottom: 10px;
    & path {
      stroke: #484343;
    }
  }
  & .icon_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #484343;
    & svg {
      margin-bottom: 0;
      width: 24px;
      height: 24px;
    }
  }
  .separator {
  }
}

.view_mode_item_wrapper {
  width: 288px;
  padding: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
  &:hover {
    background: #e8ebfa;
  }
}

.content_main_section_wrapper {
  background: #f5f6ff;
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100%;
}

.builder_wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.builder_wrapper::-webkit-scrollbar {
  width: 8px;
  background-color: #fafaff;
}
.builder_wrapper::-webkit-scrollbar-thumb {
  display: block;
  border-radius: 8px;
  background-color: #e2e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.builder_wrapper::-webkit-scrollbar-track-piece {
  display: none;
  background-color: #fafaff;
}

.stripe_place {
  height: 100%;
  position: absolute;
  left: 125px;
  width: 2px;
}

.viewer_empty_place{
  height: 110px;
}

.empty_state_wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  gap: 10px;  

  .empty_state_title {
    text-transform: uppercase;
  }
  p {
    color: #4B5563;
    font-family: RobotoFlex, sans-serif;
    margin: 0;
  }
}