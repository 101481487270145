.wrapper {
}
.flex {
  display: flex;
}
.wrapper2 {
  min-height: calc(100vh - 1px);
  background-color: #fafaff;
  display: flex;
  flex-direction: column;
  /*height: 100vh;*/
}

.absolute_header_icon {
  position: absolute;
  right: 200px;
  top: 22px;
  z-index: 2;
  cursor: pointer;
  width: 36px;
  height: 36px;
  padding: 8px;
  border-radius: 6px;
}
.absolute_header_icon:hover {
  background-color: #dde2f9;
}

.search {
  /*position: absolute;*/
  /*left: 199px;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
}
.search_input {
  background-color: white;
  line-height: 21px;
  width: 190px;
  border: 0;
  font-size: 14px;
}

.search_input::placeholder {
  color: #a3aacc;
}

.search_input_wrapper {
  margin-left: 8px;
  height: 40px;
  width: 260px;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
  display: flex;
  align-items: center;
}
.clear_search {
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  margin-right: 7px;
}
.clear_search:hover {
  background: #e8ebfa;
}

.multiSelectPanelWrapper {
  height: 1px;
  bottom: 0;
  left: 55px;
  position: sticky;
  z-index: 100;
}
.new_page:hover {
  background: #e8ebfa;
}
.new_page {
  position: relative;
  font-family: "RobotoFlex", sans-serif;
  border: 1px solid #0026e5;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*margin-top: 8px;*/
  width: 103px;
  height: 32px;
  color: #0026e5;
  padding: 0 14px 0 5px;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
}
.new_page svg path {
  stroke: #0026e5;
}

.upload_file {
  position: relative;
  font-family: "RobotoFlex", sans-serif;
  border: 1px solid #0026e5;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
  height: 32px;
  color: #0026e5;
  padding: 0 14px 0 5px;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
}
.upload_file svg path {
  stroke: #0026e5;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px 18px 0px;
  z-index: 101;
}
.orders {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  margin-right: 12px;
}
.cards_content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 48px 20px 49px;
}
.rows_content {
  padding: 9px 74px 20px 35px;
}
.rows_content.rows_balancer.rows_balancer {
  padding: 0 48px 20px 49px;
}

/*@media (min-width: 1600px) {*/
/*    !*https://www.figma.com/file/UdmMiLtT2o950ulxo4v2y0/master_Quidzi?node-id=2905%3A80051*!*/
/*    .header {*/
/*        padding: 20px 69px 16px 76px;*/
/*    }*/
/*    .orders {*/
/*        padding: 0 24px 0 75px;*/
/*    }*/
/*    .cards_content {*/
/*        padding: 7px 48px 20px 77px;*/
/*    }*/
/*    .rows_content {*/
/*        padding: 9px 70px 20px 45px;*/
/*    }*/
/*    .rows_balancer {*/
/*        padding: 4px 40px 20px 77px;*/
/*    }*/
/*}*/
/*@media (max-width: 1600px) {*/
/*    .header {*/
/*        padding: 20px 69px 16px 76px;*/
/*    }*/
/*    .orders {*/
/*        padding: 0 24px 0 55px;*/
/*    }*/
/*    .cards_content {*/
/*        padding: 7px 48px 20px 77px;*/
/*    }*/
/*    .rows_content {*/
/*        padding: 9px 40px 20px 25px;*/
/*        !*margin-left: 20px;*!*/
/*    }*/
/*    .rows_balancer {*/
/*        padding: 4px 40px 20px 55px;*/
/*    }*/
/*}*/
/*@media (max-width: 1500px) {*/
/*    .header {*/
/*        padding: 20px 69px 16px 76px;*/
/*    }*/
/*    .orders {*/
/*        padding: 0 24px 0 55px;*/
/*    }*/
/*    .cards_content {*/
/*        padding: 7px 48px 20px 77px;*/
/*    }*/
/*    .rows_content {*/
/*        padding: 9px 40px 20px 25px;*/
/*        !*margin-left: 20px;*!*/
/*    }*/
/*    .rows_balancer {*/
/*        padding: 4px 46px 20px 55px;*/
/*    }*/
/*}*/
.folderTitle {
  display: block;
  margin-left: 6px;
  margin-right: 8px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 30px;
  text-overflow: ellipsis;
  max-width: 478px;
  white-space: nowrap;
  overflow: hidden;
}

.title {
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 30px;
  text-overflow: ellipsis;
  max-width: 478px;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
}

.hide {
  visibility: hidden;
  position: absolute;
}

.folderTitle:hover {
  margin-left: 0;
  margin-right: 2px;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: text;
}

.title_input_wrapper {
  width: fit-content;
  min-width: 50px;
  max-width: 478px;
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap;*/
}

.input {
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 9px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 30px;
  background: #ffffff;
  border: 1px solid #8697f0;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input::placeholder,
.input::-webkit-input-placeholder,
.input:-moz-placeholder,
.input::-moz-placeholder,
.input:-ms-input-placeholder {
  color: #a3aacc;
}

.title_or_search {
  display: flex;
  align-items: center;
  height: 24px;
}

.title_icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.search_icon_wrapper:hover {
  background: #dde2f9;
}
.search_icon_wrapper {
  height: 32px;
  width: 32px;
  padding: 6px;
  margin-left: 10px;
  border-radius: 6px;
}

.search_icon {
  width: 20px;
  cursor: pointer;
}

.search_icon2 {
  cursor: pointer;
  padding: 8px 8px 8px 7px;
  width: 35px;
}
.folder_icon {
  margin-right: 8px;
  width: 20px;
}
.folder_icon.favorites {
  margin-top: -3px;
}
.folder_icon path {
  stroke: #596080;
  fill: #dde1f5;
}
.folder_icon rect {
  fill: #fafbff;
}
.page {
  width: 171px;
  height: 280px;
  margin: 0 18px 20px 0;
}

.emptyFavorite {
  display: flex;
  flex-direction: column;
  margin: 35vh auto 0 auto;
  align-items: center;
}
.emptyFavorite img {
  width: 48px;
  height: 58.67px;
}

.emptyFavorite_mainText {
  margin-top: 18px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #242633;
  text-align: center;
}

.emptyFavorite_infoText {
  margin-top: 8px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  text-align: center;
}

.preview:hover {
  border: 1px solid #0d26e5;
}
.preview {
  width: 171px;
  height: 250px;
  background-color: #fdf8f8;
  border: 1px solid #d7ddf7;
  border-radius: 8px;
  cursor: pointer;
}

.filters {
  display: flex;
  font-size: 14px;
}
.filters_option {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-right: 20px;
  display: flex;
  cursor: pointer;
  padding-left: 2px;
  color: #a3aacc;
  white-space: nowrap;
}
.checkbox_wrapper {
  margin-left: 7px;
  width: 17px;
  height: 17px;
}
.filters_option img {
  /*height: 20px;*/
}
.filters_option_text {
  /*padding-left: 2px;*/
  color: #596080;
  margin-right: 4px;
}

.filters_no_folder_text {
  padding-left: 2px;
  color: #596080;
  width: 99px;
}
.filters_no_folder_text__active {
  padding-left: 2px;
  color: #0026e5;
  font-weight: 500;
  width: 99px;
}
.in_folder_toggler:hover {
  background: #dde2f9;
}
.in_folder_toggler:hover .in_folder_toggler_img {
  background: #dde2f9;
}
.in_folder_toggler:hover .in_folder_toggler_knob {
  background: #dde2f9;
}
.in_folder_toggler {
  width: fit-content;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 31px;
  padding: 0 11px 0 6px;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
}
.in_folder_toggler_text {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
}
.in_folder_toggler_img {
  margin-right: 9px;
  position: relative;
  display: flex;
  align-items: center;
  width: 24px;
  height: 12px;
  background: #f5f6ff;
  border: 1px solid #bbc1e1;
  border-radius: 12px;
  cursor: pointer;
  -webkit-transition-property: border;
  -moz-transition-property: border;
  -o-transition-property: border;
  transition-property: border;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  left: -1px;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.in_folder_toggler_img_right {
  border: 1px solid #0026e5;
}
.in_folder_toggler_knob {
  position: absolute;
  width: 12px;
  height: 12px;
  background: #f5f6ff;
  border: 1px solid #bbc1e1;
  border-radius: 50%;
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  left: -1px;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.in_folder_knob_left {
  left: -1px;
}
.in_folder_knob_right {
  border: 1px solid #0026e5;
  left: 11px;
}
.sort_and_view {
  display: flex;
  justify-content: flex-end;
  width: 192px;
  align-items: center;
  font-size: 14px;
}
.language_selector {
  position: relative;
  height: 32px;
  margin-right: 10px;
  top: 2px;
}
.sort_wrapper {
  width: auto;
}
.sort {
  height: 31px;
  margin-right: 12px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 8px 0 6px;
  cursor: pointer;
  position: relative;
  width: fit-content;
}
.sort img {
  width: 20px;
  margin-right: 10px;
}
.sort:hover {
  background: #e8ebfa;
  border-radius: 7px;
}
.sort_active {
  background: #e8ebfa;
  border-radius: 7px;
}
.view {
  cursor: pointer;
  width: 36px;
  height: 36px;
  padding: 8px;
  border-radius: 6px;
}

.view:hover {
  background: #dde2f9;
}
.checkbox {
  margin: 2px 5px 1px 1px;
}

@media not all and (min-resolution: 0.001dpcm) {
  //@media {
    .checkbox {
      margin: 3px 6px 1px 1px;
      -webkit-transform: scale(1.2);
    //}
  }
}

.create_new_page {
  margin-top: 1px;
  border: 1px solid #0026e5;
  box-sizing: border-box;
  border-radius: 8px;
  width: 171px;
  height: 250px;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}
.create_new_page_list {
  margin-left: 58px;
  margin-top: 20px;
}
.create_new_page:hover {
  background: #e8ebfa;
}
.icon_page_40 {
  width: 48px;
}

.icon_new_page {
  width: 110px;
  margin-top: 8px;
}

.page_unmount {
  width: 171px;

  height: 280px;
  margin: 0 18px 20px 0;
  animation: fadein 1.5s;
  -moz-animation: fadein 1.5s; /* Firefox */
  -webkit-animation: fadein 1.5s; /* Safari and Chrome */
  -o-animation: fadein 1.5s;
}
/*.page:hover{*/
/*    background-color: red;*/
/*}*/

@keyframes fadein {
  0% {
    opacity: 1;
    width: 171px;
  }
  30% {
    width: 160px;
    opacity: 0.3;
    margin: 0;
  }

  60% {
    width: 60px;
    opacity: 0.1;
    margin: 0;
  }

  100% {
    width: 0;
    opacity: 0;
    margin: 0;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  0% {
    opacity: 1;
    width: 171px;
  }
  30% {
    width: 160px;
    opacity: 0.3;
    margin: 0;
  }

  60% {
    width: 60px;
    opacity: 0.1;
    margin: 0;
  }

  100% {
    width: 0;
    opacity: 0;
    margin: 0;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  0% {
    opacity: 1;
    width: 171px;
  }
  30% {
    width: 160px;
    opacity: 0.3;
    margin: 0;
  }

  60% {
    width: 60px;
    opacity: 0.1;
    margin: 0;
  }

  100% {
    width: 0;
    opacity: 0;
    margin: 0;
  }
}
@-o-keyframes fadein {
  /* Opera */
  0% {
    opacity: 1;
    width: 171px;
  }
  30% {
    width: 160px;
    opacity: 0.3;
    margin: 0;
  }

  60% {
    width: 60px;
    opacity: 0.1;
    margin: 0;
  }

  100% {
    width: 0;
    opacity: 0;
    margin: 0;
  }
}

.selection_border {
  background: rgba(0, 162, 255, 0.4);
  position: fixed;
  z-index: 199;
}

.folderTitleInputWrapper {
  margin-right: 2px;
}
.folderTitleInputWrapper input {
  width: 100%;
}


.is_help {
  @media (max-width: 1023.8px) {
    padding-left: 28px;
  }

  @media (min-width: 1023.9px) {
    padding-left: 4px;
  }

  @media (min-width: 1324.9px) {
    padding-left: 4px;
  }

  @media (min-width: 1545.9px) {
    padding-left: 4px;
  }

  .header {
    max-width: 1482px;
    margin: 0 16px;
    padding: 19px 0 18px 0;
  }

  .orders {
    margin: 0;
  }


}
