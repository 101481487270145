@import "./src/_mixins";

// library fullscreen header
.upload_line {
  background-color: white;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.folderTitle {
  display: block;
  margin-left: 6px;
  margin-right: 8px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 30px;
  max-width: 478px;
  @include ellipsis;
  &.hoverable:hover {
    margin-left: 0;
    margin-right: 2px;
    border: 1px solid #d7ddf7;
    box-sizing: border-box;
    border-radius: 6px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: text;
  }
}

.title_input_wrapper {
  width: fit-content;
  min-width: 50px;
  max-width: 478px;
}

.relative_header_icon {
  position: relative;
  margin-right: 12px;
  z-index: 2;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background-color: #e8ebfa;
  }
}

.search_input {
  background-color: white;
  line-height: 21px;
  width: 190px;
  border: 0;
  font-size: 14px;
  padding-left: 10px;
}

.search_input::placeholder {
  color: #a3aacc;
}

.clear_search {
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  margin-right: 7px;
}

.clear_search:hover {
  background: #e8ebfa;
}

.detailsContainer {
  display: flex;
  //position: absolute;
  //right: 68px;
  //top: 32px;
  justify-content: flex-end;
  align-items: center;
}
.button_parent_width {
  width: 117px;
  position: relative;
  margin-right: 1px;
}
.upload_button {
  margin-right: 5px;
  & svg path {
    stroke: white;
  }
}

.upload_file {
  overflow: hidden;
  position: relative;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-family: "RobotoFlex", sans-serif;
  border: 1px solid #0026e5;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: 32px;
  color: #0026e5;
  padding: 0 6px 0 2px;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }

  &.hasActiveUploads {
    color: #242633;
  }

  &.active {
    background: #e8ebfa;
  }

  &:hover {
    background: #dde2f9;
  }
}

.upload_file svg path {
  stroke: #0026e5;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 70px 12px 26px;
  margin-bottom: 12px;
  &.isChannels {
    margin-bottom: 0;
  }
}

.wrapper_dynamicCollection {
  padding: 0 70px 0 50px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  &.isChannels {
    margin-bottom: 0;
  }
  &.show_description {
    padding: 0 31px;
  }
}

.header_container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  //min-height: 136px; // Safari header height fix
  z-index: 101;

  padding-left: 0px;

  .options_wrapper {
    display: flex;
  }
}
.icon_wrapper {
  position: relative;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #e8ebfa;
  }
  &.active {
    background: #e8ebfa;
  }
}
.confirm_button_Smart_view {
  display: flex;
  margin-left: 15px;
}

.navBarBallanser {
  margin-left: 4px;
}

.header_navigation {
  display: flex;
  flex-direction: column;
  margin: 24px 46px 16px 55px;
  height: 36px;

  .navigation_bars {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 10px;
    height: 20px;

    .text_container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      height: 36px;
      box-sizing: content-box;
      cursor: pointer;

      .text {
        font-family: "RobotoFlex", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #596080;

        &.active {
          color: #242633;
          font-weight: 600;
          font-family: "Roboto", sans-serif;
        }
      }
    }
  }

  .navigation_slider {
    position: relative;
    padding-top: 5px;

    .long_line {
      position: absolute;
      width: 99.5%;
      height: 1px;
      bottom: -1px;
      background: #e4e7f7;
    }

    .underline_all {
      transition-property: width, left;
      position: relative;
      height: 2px;
      left: 0;
      width: 24px;
      bottom: -1px;
      background: #0026e5;
      transition-duration: 0.4s;
    }

    .underline_components {
      transition-property: width, left;
      position: relative;
      height: 2px;
      left: 39px;
      width: 93px;
      bottom: -1px;
      background: #0026e5;
      transition-duration: 0.4s;
    }

    .underline_media {
      transition-property: width, left;
      position: relative;
      height: 2px;
      left: 147px;
      width: 42px;
      bottom: -1px;
      background: #0026e5;
      transition-duration: 0.4s;
    }

    .underline_links {
      transition-property: width, left;
      position: relative;
      height: 2px;
      left: 203px;
      width: 39px;
      bottom: -1px;
      background: #0026e5;
      transition-duration: 0.4s;
    }

    .underline_docs {
      transition-property: width, left;
      position: relative;
      height: 3px;
      left: 257px;
      width: 39px;
      bottom: -1px;
      background: #0026e5;
      transition-duration: 0.4s;
    }
  }
}

.orders {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 8px 66px 20px 49px;
}

.header {
  padding: 19px 70px 12px 49px;
}
.header.rows {
  padding: 19px 70px 12px 49px;
}
.orders {
  padding: 8px 66px 20px 49px;
}
.orders.rows {
  //height: 46px;
  padding: 22px 70px 12px 49px;
}
.orders.rows.channels {
  padding: 22px 70px 12px 26px;
}
//.header.rows {
//  padding: 20px 40px 12px 44px;
//}
.header_navigation {
  margin: 0 70px 20px 49px;
}
.cards_content {
  padding: 4px 76px 20px 49px;
}
.rows_content {
  padding: 4px 0 20px 49px;
}

.title_or_search {
  display: flex;
  align-items: center;
  height: 32px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 24px;
}

//
.search_icon_wrapper:hover {
  background: #dde2f9;
}

.search_icon_wrapper {
  height: 32px;
  width: 32px;
  padding: 6px;
  margin-left: 10px;
  border-radius: 6px;
  cursor: pointer;
}

//
.search_icon {
  width: 20px;
  cursor: pointer;
}

.search_icon2 {
  cursor: pointer;
  padding: 8px 8px 8px 7px;
  width: 35px;
}
.search_svg_icon2 {
  display: flex;
  margin-left: 7px;
  margin-right: 7px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #e8ebfa;
    & svg path {
      stroke: #242633;
    }
  }
}

.folder_icon {
  margin-right: 6px;
  width: 20px;

  path {
    stroke: #596080;
    fill: #dde1f5;
  }
  &.favorite {
    margin-top: -3px;
  }
}

.sort_and_view {
  display: flex;
  justify-content: flex-end;
  //width: 400px;
  align-items: center;
  font-size: 14px;
  width: 104px;
  position: relative;
  &.isFullScreen {
    & .sort {
      padding: 0 6px;
      margin: 0 4px;
      & svg path {
        fill: #ffffff;
      }
    }
    & .sort img {
      width: 20px;
      margin-right: 0;
    }
  }
}

.sort_wrapper {
  width: 169px;
}

.sort {
  white-space: nowrap;
  height: 31px;
  width: fit-content;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 14px 0 3px;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  &.disabled {
    cursor: no-drop;
    opacity: 0.4;
  }
  &.low_mode.low_mode {
    height: 28px;
    margin-left: 0;
    padding-left: 0;
  }
}

.sort img {
  width: 20px;
  margin-right: 10px;
}

.sort.disabled {
  cursor: no-drop;
  background: initial;
  opacity: 0.4;
}

.sort_active {
  //background: #E8EBFA;
  //border-radius: 7px;
}

.view {
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view:hover {
  background: #dde2f9;
}

.filters {
  display: flex;
  font-size: 14px;
}

.filters.collapsed_large {
  display: none;
}

.filters.collapsed_small {
  display: flex;
  & svg {
    margin-right: 8px;
  }
}

@media (min-width: 1600px) {
  .filters.collapsed_small {
    display: none;
  }
  .filters.collapsed_large {
    display: flex;
  }
}

.filters_option {
  position: relative;
  font-family: "RobotoFlex", sans-serif;
  font-size: 14px;
  line-height: 21px;
  margin-right: 21px;
  display: flex;
  cursor: pointer;
  color: #a3aacc;
  white-space: nowrap;
}

.filters_option img {
  /*height: 20px;*/
}

.filters_option_text {
  /*padding-left: 2px;*/
  color: #596080;
  margin-right: 4px;
  &.blue {
    color: #0026e5;
    & svg path {
      stroke: #0026e5;
    }
  }
}

.options {
  &_button {
    width: 32px;
    height: 32px;
    //padding: 5px;
    border: 1px solid #d7ddf7;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background: #e8ebfa;
    }
  }

  &_button2 {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

.options_button {
  position: relative;
}

.options_button.active {
  background: #e8ebfa;
}

.options_button_create_com.active {
  background: #e8ebfa;
}

.folderTitleInputWrapper {
  margin-right: 2px;
}

.folderTitleInputWrapper input {
  width: 100%;
}

//Library sideBar Header
.sideBarLib__header_container {
  display: flex;
  flex-direction: column;
  padding: 13px 16px 4px 13px;
  background: #ffffff;
  &.dynamic_collection {
    padding-left: 16px;
  }
}

.sideBarLib__header_option {
  display: flex;
  justify-content: space-between;
  padding: 3px 0;
  .sideBarLib__header_title {
    &.dynamic_collection {
      display: flex;
      justify-content: space-between;
      width: 100%;
      & .title_group {
        display: flex;
        align-items: center;
        & .editableTitle {
          display: block;
          margin-left: 6px;
          margin-right: 8px;
          font-weight: 600;
          font-family: "Roboto", sans-serif;
          font-size: 20px;
          line-height: 30px;
          max-width: 356px;
          width: fit-content;
          @include ellipsis;
          &:hover {
            margin-left: 0;
            margin-right: 2px;
            border: 1px solid #d7ddf7;
            box-sizing: border-box;
            border-radius: 6px;
            padding-left: 5px;
            padding-right: 5px;
            cursor: text;
          }
        }
        & .inputField {
          padding-left: 5px;
          padding-right: 5px;
          margin-right: 9px;
          font-weight: 600;
          font-family: "Roboto", sans-serif;
          font-size: 20px;
          line-height: 30px;
          background: #ffffff;
          border: 1px solid #8697f0;
          border-radius: 6px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          @include ellipsis;
        }
      }
    }
    & .icon_wrapper {
      position: relative;
      border-radius: 6px;
      width: 32px;
      height: 32px;
      margin: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: #e8ebfa;
      }
      &.active {
        background: #e8ebfa;
      }
    }
    display: flex;
    .title {
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      width: 360px;
      display: flex;
      align-items: center;
      padding-left: 6px;
    }
  }
  .options_wrapper {
    display: flex;
  }
  .search_wrapper {
    position: absolute;
    left: 131px;
    .search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      position: absolute;
      top: -4px;
    }
  }
  .expand {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    position: relative;
    padding: 4px 6px 6px 7px;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background: #e8ebfa;
    }

    &.active {
      background: #e8ebfa;
    }
  }
  .close_button {
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: #0026e5;
    font-size: 12px;
    line-height: 12px;
    cursor: pointer;
    &:hover {
      background: #e8ebfa;
      & svg path {
        stroke: #242633;
      }
    }

    &.active {
      background: #e8ebfa;
    }
  }
}

.sideBarLib__header_tabs_container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-top: 13px;

  &.playlists_balancer {
    padding-bottom: 9px;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    padding-left: 3px;
    align-items: center;
    width: clamp(330px, 40%, 600px);

    &.isPlaylistBuilder {
      padding-left: 4px;
      width: clamp(230px, 40%, 600px);
      & .tab {
        padding: 5px 9px 6px 9px;
        margin-right: 4px;
      }
    }

    .tab {
      position: relative;
      padding: 5px 6px 6px 7px;
      margin-right: 10px;
      cursor: pointer;
      border-radius: 4px;
      width: fit-content;
      height: 32px;
      display: flex;
      justify-content: left;
      align-items: center;
      font-family: "RobotoFlex", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #242633;
      white-space: nowrap;

      &:hover {
        background: #e8ebfa;
      }

      &.active {
        background: #e8ebfa;
      }
    }

    .lib_tab {
      min-width: 62px;
    }
  }

  //.expand {
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //  width: 32px;
  //  height: 32px;
  //  position: relative;
  //  padding: 5px 6px 6px 7px;
  //  margin-right: 6px;
  //  cursor: pointer;
  //  border-radius: 4px;
  //
  //}

  .plus_button {
    position: relative;
    font-family: "RobotoFlex", sans-serif;
    border: 1px solid #0026e5;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: #0026e5;
    font-size: 12px;
    line-height: 12px;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: #0026e5;
      }
    }

    &.active {
      background: #e8ebfa;
    }
  }
}

.sideBarLib__search_and_sort {
  position: relative;
  display: flex;
  justify-content: space-between;
  .fit_content {
    width: fit-content;
  }
  .sideBarLib__search {
    display: flex;
    height: 32px;
    width: 355px;
    max-width: 355px;
    min-width: 160px;
    border: 1px solid #d7ddf7;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: white;
    align-items: center;

    .input {
      background-color: white;
      line-height: 21px;
      width: 285px;
      border: 0;
      font-size: 14px;
    }

    .input::placeholder {
      color: #a3aacc;
    }

    .icon {
      cursor: pointer;
      padding: 8px 12px 8px 7px;
      width: 35px;
    }
  }
  .filters {
    cursor: pointer;
    margin: 12px 7px;
    & svg {
      margin-right: 8px;
      //cursor: pointer;
    }
  }
}

.sideBarLib__sort_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  margin-top: 0;
  padding-right: 7px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  &.sort_active {
    background-color: #e8ebfa;
  }
  &:hover {
    background-color: #e8ebfa;
  }

  .sort {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    padding: 5px;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin-right: 0;

    img {
      width: 20px;
      margin-right: 0;
    }
  }
  .text {
    padding-left: 3px;
    white-space: nowrap;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #242633;
  }
}

.sideBarLib__sort_and_view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  /* margin-left: 21px; */
  /* margin-bottom: 19px; */
  margin-top: 0;
  margin-right: 7px;

  .sort {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    padding: 5px;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin-right: 0;

    img {
      width: 20px;
      margin-right: 0;
    }
  }
  .text {
    padding-left: 3px;
    white-space: nowrap;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #242633;
  }

  .view {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    padding: 5px;
    align-items: center;
    position: relative;
    margin-right: 0;
    margin-left: 15px;
    cursor: pointer;
    img {
      width: 20px;
      margin-right: 0;
    }
  }
}

.sideBarLib__header_navigation {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 36px;

  .navigation_bars {
    display: flex;
    align-items: center;
    margin: 0;
    .text_container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-right: 16px;
      height: 20px;
      box-sizing: content-box;
      cursor: pointer;
      border-bottom: 3px;

      .text {
        font-family: "RobotoFlex", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #596080;
      }

      .text.active {
        font-style: normal;
        font-weight: 600;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #242633;
      }
    }

    .text_container.active {
      //border-bottom: 3px solid #0026E5;
    }
  }
  .navigation_slider {
    position: relative;
    .long_line {
      position: absolute;
      width: 105%;
      height: 1px;
      left: -16px;
      bottom: -1px;
      background: #e4e7f7;
    }

    .underline_all {
      transition-property: width, left;
      position: relative;
      height: 2px;
      left: 0;
      width: 24px;
      bottom: -1px;
      background: #0026e5;
      transition-duration: 0.4s;
    }

    .underline_components {
      transition-property: width, left;
      position: relative;
      height: 2px;
      left: 39px;
      width: 93px;
      bottom: -1px;
      background: #0026e5;
      transition-duration: 0.4s;
    }

    .underline_media {
      transition-property: width, left;
      position: relative;
      height: 2px;
      left: 147px;
      width: 42px;
      bottom: -1px;
      background: #0026e5;
      transition-duration: 0.4s;
    }

    .underline_links {
      transition-property: width, left;
      position: relative;
      height: 2px;
      left: 203px;
      width: 39px;
      bottom: -1px;
      background: #0026e5;
      transition-duration: 0.4s;
    }

    .underline_docs {
      transition-property: width, left;
      position: relative;
      height: 3px;
      left: 257px;
      width: 39px;
      bottom: -1px;
      background: #0026e5;
      transition-duration: 0.4s;
    }
  }
}
.sideBarLib__orders {
  margin-bottom: 20px;
}
.search_input_wrapper {
  margin-left: 8px;
  height: 40px;
  width: 260px;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
  display: flex;
  align-items: center;
  &.allLibraryHeader {
    margin-left: 6px;
  }
}
.hide {
  visibility: hidden;
  position: absolute;
}
.input2 {
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 9px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 30px;
  background: #ffffff;
  border: 1px solid #8697f0;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @include ellipsis;
}

.input2::placeholder,
.input2::-webkit-input-placeholder,
.input2:-moz-placeholder,
.input2::-moz-placeholder,
.input2:-ms-input-placeholder {
  color: #a3aacc;
}

.slider_and_sort_container {
  position: relative;
  display: flex;
  left: 2px;
  align-items: initial;
  justify-content: space-between;
  padding: 11px 0 20px 26px;
  height: auto;
  &.isUsers {
    padding-bottom: 10px;
    padding-right: 22px;
    padding-top: 22px;

  }
  &.forChannels {
    padding: 19px 0 12px 4px;
    margin: 0 16px;
  }
}
.menu {
  position: absolute;
  right: -43px;
  top: 11px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 116px;
  cursor: pointer;
  margin-top: 11px;
  margin-right: 8px;
  & .gridViewIcon,
  & .hideIcon {
    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: #596080;
      }
    }
  }
  & .hideIcon {
    svg {
      width: 16px;
      height: 16px;

      path {
        stroke: #596080;
      }
    }
    &.active svg path {
      stroke: #8f8f8f;
      fill: #919191;
    }
    &.active {
      background-color: rgba(232, 235, 250, 0.55);
    }
  }

  & .favoriteIcon,
  & .gridViewIcon,
  & .hideIcon {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #e8ebfa;
    }
  }
  & .favoriteIcon svg path {
    stroke: #596080;
  }
  & .favoriteIcon.active svg path {
    stroke: #ff8a00;
    fill: #ff8a00;
  }
}
.slider_parent {
  width: 100%;
  min-width: 380px;
  margin-right: 35px;
  &.isChannels {
    margin-right: 0;
  }
  &.isUsers {
    width: 85%;
    height: 40px;
  }
  &.collapsible {
    margin-right: 0;
  }
}
.top_sorting_and_add_file {
  display: flex;
  align-items: center;
  & .btn_wrapper {
    margin-left: 10px;
  }
  & .sort_wrapper {
    width: 48px;
    & .sort {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 32px;
      height: 32px;
    }
    & img {
      margin: 0;
    }
  }
}
.top_sorting_parent {
  align-items: center;
  justify-content: flex-end;
  height: 31px;
}

.sorting_parent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 222px;
  padding-right: 5px;
  height: 31px;
  &.listView {
    width: 37px;
  }

  & .sort_wrapper {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #242633;
    width: auto;
    margin-right: 17px;
  }
}

@media (max-width: 1300px) {
  .top_sorting_parent {
    display: flex;
  }

  .sorting_parent {
    width: 37px;
    overflow: hidden;
  }

  //.header, .slider_and_sort_container {
  //  padding-right: 31px;
  //  padding-left: 31px;
  //}
}

@media (max-width: 1730px) {
  .isSideBarOpened {
    .sorting_parent {
      display: none;
    }

    .top_sorting_parent {
      display: flex;
    }

    .sorting_parent {
      width: 37px;
      overflow: hidden;
    }

    .header,
    .slider_and_sort_container {
      padding-right: 31px;
      padding-left: 31px;
      &.collapsible {
        padding-right: 5px;
      }
    }
  }
}
.header_edit_icon_wrapper {
  position: relative;
}
.header_edit_icon {
  left: 0;
  top: 37px;
  padding: 18px 16px;
  z-index: 2;
  position: absolute;
  width: 208px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
}

.icon_wrapper {
  cursor: pointer;
}
.SearchInSmartView_wrapper {
  //margin-top: 10px;
  position: relative;
  //width: 369px;
  //display: grid;
  //grid-template-columns: 1fr fit-content(20%);
  display: flex;
  padding: 0 70px 0 49px;
  &.is_open_description {
    margin-top: 0;
    //margin-left: 30px;
    padding: 0 31px;
  }
}

.editableTitle {
  display: block;
  margin-left: 6px;
  margin-right: 8px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 30px;
  max-width: 356px;
  width: fit-content;
  @include ellipsis;
  &:hover {
    margin-left: 0;
    margin-right: 2px;
    border: 1px solid #d7ddf7;
    box-sizing: border-box;
    border-radius: 6px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: text;
  }
}
.inputField {
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 9px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 30px;
  background: #ffffff;
  border: 1px solid #8697f0;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @include ellipsis;
}

.resize_wrapper {
  display: flex;
  grid-template-columns: 1fr fit-content(20%);
  padding: 0 70px 0 44px;
  &.show_description {
    padding: 0 31px;
  }
  &.isTag {
    margin-bottom: 20px;
  }
  & .save_search_wrapper {
    position: relative;
    margin-left: 20px;
  }
  & .search_max_size {
    width: 500px;
    & .search_input_wrapper {
      width: 500px;
    }
  }
}

.buttons_wrapper {
  margin-top: 0;
  display: flex;
  align-items: center;
  margin-left: 26px;
  .button_margin {
    margin-right: 16px;
  }
}
.wide_button_wrapper {
  width: 144px;
}
.import_button_wrapper {
  width: 145px;
}
.white_icon {
  margin: 0 9px 0 5px;
}

.usersListView {
  padding: 0 9px 0 3px;
  & img {
    margin-right: 8px;
  }
}

.orders.usersListView {
  padding-bottom: 0;
  //padding-left: 81px;
}

.user_sort {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header {
  &.topBallancer {
    margin-bottom: 7px;
  }
}

.top_sorting_parent.noRightSpacing {
  padding-right: 0;
  margin-right: 0;
}
.top_sorting_parent.playlistSpacing {
  margin-right: 5px;
}

.disable_button {
  cursor: no-drop;
  opacity: 0.4;
}

.sort.usersListView {
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #242633;
  position: absolute;
  left: -156px;
}

.add_playlist_button {
  position: relative;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  color: #0026e5;
  padding-right: 8px;
  padding-left: 6px;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
  letter-spacing: 0;
}
.item_tag {
  display: flex;
  align-items: center;
}
