@import "./src/_mixins";

.listTitle {
  padding-bottom: 51px;
  align-items: center;
  height: 32px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 24px;
  padding-left: 18px;
  width: 254px;
  margin-top: 23px;
  margin-bottom: 6px;
}

.new {
  background: rgb(245, 180, 0);
  border-radius: 12px;
  height: 20px;
  position: absolute;
  right: 8px;
  z-index: 100;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #ffffff;

  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  top: 4px;
  padding: 4px 6px;
}

.channelsListTitle {
  height: 32px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 24px;
  padding-left: 18px;
  width: 254px;
  margin-top: 23px;
  margin-bottom: 6px;
}

.playlistsListTitle {
  height: 32px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 24px;
  padding-left: 18px;
  width: 254px;
  margin-top: 23px;
  margin-bottom: 25px;
}

.wrapperListSubTitle {
  padding-right: 14px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.listSubTitle {
  padding-left: 9px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  width: 100%;
  line-height: 18px;
  text-transform: uppercase;
  color: #a3aacc;
}

.sideBar1 {
  min-height: 100vh;
  height: 100%;
  display: flex;
}

.hide {
  display: none;
}

.sideBar {
  height: 100vh;
  background: #f0f1fe;
  width: 72px;
  position: relative;
}

.logoChevronWrapper {
  visibility: hidden;
  position: absolute;
  right: -7px;
  padding: 3px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  & svg {
    width: 9px;
    height: 9px;

    & path {
      stroke: #596080;
      width: 1px;
    }
  }
}

.logoContainer {
  cursor: pointer;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 6px;

  &:hover {
    background-color: #dde2f9;
  }

  &:hover {
    & .logoChevronWrapper {
      visibility: visible;
    }
  }

  &.active {
    background-color: #dde2f9;
  }

  & svg.menuIcon {
    width: 28px;
    height: 28px;
  }
}

.logoChevronWrapper.active {
  visibility: visible;
}

.menuIcon {
  width: 24px;
  height: 24px;
}

.menu__open_user_menu {
  position: absolute;
  left: 50px;
  top: 35px;
  cursor: pointer;
  opacity: 0.5;
  height: 6px;
}

.menu__close_bar {
  position: absolute;
  left: 205px;
  opacity: 0;
  cursor: pointer;
}

.sideBar:hover .menu__close_bar {
  opacity: 1;
}

.menuIconIsClose {
  left: 24px;
  top: 26px;
  padding: 25px 0 25px 25px;
  cursor: pointer;
}

.icon {
  /*margin-right: 12px;*/
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.menu_icon {
  /*margin-right: 12px;*/
  width: 20px;
  height: 20px;
}

.icon_page {
  margin-right: 12px;
  width: 16px;
}

.text {
  padding-top: 4px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #596080;
}

.menu_item__active .text {
  color: #242633;
}

.folder_name {
  width: 174px;
  height: 19px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #585858;

  &.active {
    color: #484343;
    font-weight: 500;
  }
}

.folder_name.channel_item {
  width: 140px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-left: 1px;
  font-size: 16px;
  font-weight: 500;
}

.page_text_is_nested {
  width: 135px;
}

.input {
  padding-left: 6px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #ffc72c;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 232px;
  z-index: 18;
}

.text_active {
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.heading_text {
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  width: 155px;
  color: #a3aacc;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.plus_wrapper {
  /*padding-left: 15px;*/
  width: 32px;
  height: 32px;
  padding: 6px;
  cursor: pointer;
  border-radius: 4px;
}

.plus_wrapper:hover {
  /*margin-left: auto;*/
  background: #dde2f9;
}

.plus__active {
  cursor: pointer;
  background-image: url("../../images/icons/rectangle_page.svg");

  /*left: 2px;*/
  /*bottom: 2px;*/
  /*height: 24px;*/
  /*width: 24px;*/
}

.navGroup {
  //padding-top: 70px;
}

.pagePlaylistSelector {
  display: flex;
  width: 100%;
  padding-left: 1px;
  margin: 16px 5px 5px;
}

.navPages {
  margin-top: 42px;
  position: relative;
}

.item {
  padding: 2px 0;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.item_view {
  padding: 2px 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  line-height: 16px;

  .item_core_info {
    height: 16px;
    display: flex;
  }

  .item_view_text {
    color: #242633;
    width: 170px;
    @include ellipsis;
  }
}

.item_tag {
  padding: 2px 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 184px;
}

.menu_item__active.menu_item__active {
  background: #dde2f9;
  font-weight: 600;
  font-family: "Roboto", sans-serif;

  & svg {
    & path {
      stroke: rgb(36, 38, 51);
    }
  }

  & .menu_icon {
    path,
    polygon,
    rect,
    line {
      stroke: #242366;
    }
  }

  & .text {
    color: #242366;
  }
}

.page__no_active {
  background: #f5f6ff;
  font-weight: normal;
}

.navPages .page_rename {
  padding: 0 18px 2px 18px;
}

.navPages .page_rename .item {
  padding: 1px 0 0 0;
}

.navPages .page_rename.page_is_nested {
  padding-left: 42px;
}

.navPages .page_rename.page_is_nested input {
  margin: 1px 0 0 0;
  width: 133px;
}

.itemMenu_wrapper {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 52px;
  z-index: 102;
}

.itemMenu {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 48px;

  &.trashWrap {
    width: 30px;
    height: 30px;
  }

  border-radius: 8px;

  &.active {
    background: #ffc72c;
  }

  & .menu_icon {
    path,
    polygon,
    rect,
    line {
      stroke: #596080;
    }
  }

  &:hover .menu_icon {
    path,
    polygon,
    rect,
    line {
      stroke: #242366;
    }
  }

  &:hover .text {
    color: #242366;
  }
}

.itemMenu:hover {
  background: #ffeebf;
}

.itemMenu.disabled:hover {
  background: none;
}

.count_page {
  position: absolute;
  right: 10px;
  font-family: "RobotoFlex", sans-serif;
  font-size: 14px;
  color: #a3aacc;
  padding-right: 3px;
}

.count_page__not_in_folder {
  display: block !important;
}

.info {
  display: flex;
  width: 33px;
  //justify-content: space-between;
  justify-content: flex-end;
}

.page:hover .item_hidden {
  cursor: pointer;
  opacity: 1;
}

.folders_item:hover .count_page {
  cursor: pointer;
  display: none;
}

.count_channel {
  position: absolute;
  height: 19px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #939393;
  right: 14px;

  &.hide {
    display: none;
  }

  &.active {
    color: #484343;
    font-weight: 500;
  }
}

.test {
  background: #e8ebfa;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.plus_add_page {
  cursor: pointer;
  opacity: 0;
  display: flex;
  width: 20px;
  height: 20px;
  padding: 2px;
}

.plus_add_page:hover {
  background-image: url("../../images/icons/rectangle_page.svg");
}

.folder:hover .plus_add_page {
  opacity: 1;
}

.folders_item:hover .item_hidden {
  cursor: pointer;
  opacity: 1;
}

.folder:hover {
  cursor: pointer;
  background: #e8ebfa;
}

.folder_has_drag {
  cursor: pointer;
  background: #ffeebf;
  border: 1px solid #F5B400;
}

.folder_drag.folder_drag {
  opacity: 0.4;
  background: #f5f6ff !important;
}

.page:hover {
  background: #e8ebfa;
}

.page_is_nested {
  padding: 3px 18px 4px 42px;
}

.page_is_not_nested {
  padding: 3px 18px 4px 18px;
}

.item_hidden {
  opacity: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 2px;
}

.rectangle_page {
  opacity: 1;
  background-image: url("../../images/icons/rectangle_page.svg");
  z-index: 10;
}

.item_hidden:hover {
  opacity: 1;
  background-image: url("../../images/icons/rectangle_page.svg");
}

.hidden_nested_page {
  overflow: hidden;
  max-height: 0;
}

.show_nested_page {
  /*transition-property: height;*/
  /*transition-duration: 3s;*/
  overflow: hidden;
  max-height: 500px;
}

.nested_page {
  overflow: hidden;
  transition-property: max-height;
  transition-duration: 1s;
}

.page {
  /*margin-top: 4px;*/
  display: flex;
  justify-content: space-between;
}

.folder {
  padding: 4px 14px 4px 9px;
  display: flex;
  justify-content: space-between;
}

.folder_has_input {
  padding: 0 15px 1px 15px;
  background-color: #ffffff !important;
}

.trash_has_drag {
  background: #ffe1e8;
  color: #c21c44;
}

.trash_has_drag .text {
  color: #c21c44;
}

.trash_has_drag svg path {
  stroke: #c21c44;
}

.no_page {
  padding-left: 44px;
  font-family: "RobotoFlex", sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #7e8592;
  padding-bottom: 2px;
}

.chevron {
  margin-right: 10px;
  margin-left: 6px;
  transition-property: transform;
  transition-duration: 0.1s;
}

.rotate {
  transform: rotate(90deg);
}

.chevron_has_input {
  margin-right: 1px;
  width: 19px;
  margin-left: 0;
  margin-top: 1px;
}

//.newPlaylist {
//  display: flex;
//  margin-top: 2px;
//}

/*.plus_wrapper {*/
/*    position: relative;*/
/*    bottom: 4px;*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

.header_item {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0 14px 17px 15px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  height: 35px;
}

.header_pagesItem {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0 14px 17px 15px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  height: 35px;
}

.scroll_wrapper {
  padding-top: 65px;
  height: calc(100vh - 200px);
}

.menu {
  /*height: 100vh;*/
}

.img_wrapper {
  padding-top: 2px;
  height: 20px;
}

.img_align_wrapper {
  display: flex;
  align-items: center;
}

.trash:hover .item_hidden {
  cursor: pointer;
  opacity: 1;
}

.trash:hover {
  background: #e8ebfa;
}

.trash {
  /*margin-top: 32px;*/
  padding: 3px 18px 4px 18px;
  display: flex;
  justify-content: space-between;
}

.trash_wrapper {
  margin-top: 20px;
  padding: 13px 0 20px 0;
}

.left_nav_bar_separator {
  width: 43px;
  height: 1px;
  margin: 5px auto;
  background: #a3aacc;
}

.text_separator {
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  line-height: 18px;
  text-align: center;
  color: #a3aacc;
  padding-top: 19px;
  padding-bottom: 3px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  &.list {
    position: relative;
    font-weight: normal;
    text-align: initial;
    padding-top: 0;
    padding-left: 12px;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 5px;
  }

  &.list.line {
    position: relative;
    margin-top: 19px;
    margin-bottom: 7px;

    &:before {
      position: absolute;
      content: "";
      background: #e8ebfa;
      width: 244px;
      height: 1px;
      top: -7px;
    }
  }
}

.menu_bar {
  /*height: 100vh;*/
}

.menu__last_block {
  display: flex;
  flex-direction: column;
  padding-bottom: 17px;
  position: absolute;
  bottom: 0;
  align-items: center;
  width: 72px;
}

.folders {
  height: 100vh;
  width: 256px;
  background: #f5f6ff;
}

.search {
  padding: 24px 14px 16px 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search.channels {
  padding-top: 20px;
}

.search_tag {
  padding: 9px 14px 19px 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search_input {
  background: #fafbff;
  font-size: 14px;
  line-height: 21px;
  width: 129px;
  border: 0;
}

.search_input::placeholder {
  color: #a3aacc;
}

.search_input_tag {
  background: #fafbff;
  font-size: 14px;
  line-height: 21px;
  width: 169px;
  border: 0;
}

.search_input_tag::placeholder {
  color: #a3aacc;
}

.search_input_search_icon {
  padding: 8px 7px 8px 8px;
}

.search_input_wrapper:hover {
  border: 1px solid #a3aacc;
}

.search_input_wrapper {
  height: 32px;
  width: 192px;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  background: #fafbff;
  display: flex;
  align-items: center;
}

.search_tag_input_wrapper {
  height: 32px;
  width: 232px;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  background: #fafbff;
  display: flex;
  align-items: center;
}

.playlist_item {
  width: 232px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  padding-left: 8px;
  /*justify-content: center;*/
  align-items: center;
  cursor: pointer;
}

.playlist_item_title {
  font-family: "RobotoFlex", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  color: #242633;
  font-size: 14px;
  line-height: 21px;
}

.playlist_item:hover {
  background: #ffeebf;
}

.playlist_item:hover img {
  opacity: 1;
}

.playlist_item__active {
  background: #ffc72c;
}

.folders_item {
  width: 216px;
  margin-left: 12px;
  height: 32px;
  padding-left: 34px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #ffeebf;

    .count_channel {
      display: none;
    }

    & svg rect {
      fill: #ffeebf;
    }

    .new {
      visibility: hidden;
    }
  }

  //svg path {
  //  stroke: #596080;
  //  fill: #DDE1F5;
  //}
}

.smart_view_item {
  width: 100%;
  padding-left: 17px;
  min-height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #dde2f9;
  }
}

.new_folder {
  color: #0026e5;
  width: 232px;
  border-radius: 6px;
  display: flex;
  padding-left: 8px;
  /*justify-content: center;*/
  align-items: center;
  cursor: pointer;
  animation: fadein 0.2s;
  -moz-animation: fadein 0.2s; /* Firefox */
  -webkit-animation: fadein 0.2s; /* Safari and Chrome */
  -o-animation: fadein 0.2s;
}

@keyframes fadein {
  0% {
    height: 0;
    opacity: 0;
  }
  30% {
    height: 12px;
    opacity: 0.1;
  }

  60% {
    height: 22px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    height: 32px;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  0% {
    height: 0;
    opacity: 0;
  }
  30% {
    height: 12px;
    opacity: 0.1;
  }

  60% {
    height: 22px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    height: 32px;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  0% {
    height: 0;
    opacity: 0;
  }
  30% {
    height: 12px;
    opacity: 0.1;
  }

  60% {
    height: 22px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    height: 32px;
  }
}

@-o-keyframes fadein {
  /* Opera */
  0% {
    height: 0;
    opacity: 0;
  }
  30% {
    height: 12px;
    opacity: 0.1;
  }

  60% {
    height: 22px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    height: 32px;
  }
}

.new_folder:hover {
  background: #e8ebfa;
}

.new_folder svg path {
  stroke: #0026e5;
}

.new_folder svg {
  margin-right: 10px;
}

.new_folder_title {
  font-family: "RobotoFlex", sans-serif;
  width: 160px;
  font-size: 14px;
  line-height: 21px;
}

.folders_item_title {
  font-family: "RobotoFlex", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  color: #242633;
  font-size: 14px;
  line-height: 21px;
}

.folders_item__active {
  background: #ffc72c;

  & svg rect {
    fill: #ffc72c;
  }
}

.folders_group {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  flex-direction: column;
}

.separator {
  background: #e8ebfa;
  width: 100%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 7px;
}

.channel {
  //width: 254px;
  //overflow-y: hidden;
}

.channelLong {
  height: calc(100vh - 586px);
}

.channelShort {
  height: calc(100vh - 426px);
}

.relativeWrapper {
  position: relative;
  width: 254px;
  height: calc(100% - 195px);
  //overflow-y: auto;
  &.channels {
    position: relative;
    height: fit-content;
  }
}

.create_new_channel {
  font-size: 14px;
  cursor: pointer;
  position: relative;
  height: 32px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "RobotoFlex", sans-serif;
  font-weight: 400;
  color: #596080;
  padding-left: 20px;
  padding-right: 3px;
  width: 256px;
  margin-left: 23px;
  &:hover {
    background-color: #ffeebf;
  }
}

.relativeWrapperLibrary {
  position: relative;
  width: 254px;
  height: calc(100% - 267px);
  //overflow-y: auto;
  &.channels {
    position: relative;
    height: fit-content;
  }
}

.relativeContainer {
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &.channels {
    position: relative;
    height: auto;
    overflow-y: initial;
  }
}

.relativeContainer::-webkit-scrollbar {
  display: none;
  width: 8px;
}

.relativeContainer::-webkit-scrollbar-thumb {
  display: block;
  border-radius: 8px;
  background-color: #e2e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.relativeContainer::-webkit-scrollbar-track-piece {
  display: none;
}

.relativeContainer::-webkit-scrollbar-track {
  border-radius: 10px;
}

.relative {
  position: relative;
  margin-right: 19px;
}

.newItemInput {
  margin-left: 13px;
}

.folders_found {
  color: #a3aacc;
  width: 100%;
  margin-bottom: 5px;
  padding-left: 20px;
  font-size: 11px;
  line-height: 24px;
  text-transform: uppercase;
}

.clear_search {
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
}

.clear_search:hover {
  background: #e8ebfa;
}

.new_tag_input {
  width: 187px;
  color: #242633;
  height: 24px;
  border-radius: 6px;
  margin-left: 6px;
  margin-bottom: 6px;
  border: 1px solid #8697f0;
  background: #ffffff;
  font-size: 12px;
  line-height: 18px;

  &::placeholder {
    color: #a3aacc;
  }
}

.three_dots-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    right: 11px;
  }
}
.folders_item {
  &:hover {
    .three_dots-wrapper {
      > div {
        visibility: visible;

        >svg path {
          stroke: #585858;
          fill: transparent;
        }
      }
    }
  }
}

.blink_it {
  animation: blinking_touch 1s;
  animation-iteration-count: 2;
}

@keyframes blinking_touch {
  0% {
    background-color: #ffffff;
  }

  50% {
    background-color: #ffe9ad;
  }

  100% {
    background-color: #ffffff;
  }
}
